import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from './../../../environments/environment';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { CommonService } from '../../common/services/common.service';
import { MemberService } from '../../common/services/member.service';
import { IResponse } from '../../common/interfaces/response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-career-hobbies',
  templateUrl: './career-hobbies.component.html',
  styleUrls: ['./career-hobbies.component.scss']
})
export class CareerHobbiesComponent implements OnInit {
  public careerHobbiesForm: FormGroup;
  public isSubmitted = false;
  public showMaxlength1 = false;
  public showMaxlength2 = false;
  public update = false;
  public tinyMceKey;
  memberId: string;
  action: string;
  updateHighlightMemories: any;
  updateHighlightFamily: any;
  constructor(public router: Router, public formBuilder: FormBuilder, public commonService: CommonService, public loader: NgxSpinnerService,
    public toastr: ToastrService, public memberService: MemberService, public activatedRoute: ActivatedRoute) {
    this.tinyMceKey = environment.tinyMceKey;
    this.careerHobbiesForm = this.formBuilder.group({
      hobbies: ['', [Validators.required]],
      careerAccomplishments: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (localStorage.getItem('member_id')) {
      this.memberId = localStorage.getItem('member_id');
      this.update = true;
      this.getMemberData(this.memberId);
      this.getMemberDataHighlight(this.memberId);      
    }

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.update = true;
        this.getMemberData(this.memberId); 
        this.getMemberDataHighlight(this.memberId);      
      }
    });
    if (localStorage.getItem('update')) {
      this.update = true;
    }
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
    }, MagicNumbers.thousand);
    if (localStorage.getItem('addMember')) {
      const addMember = localStorage.getItem('addMember');
      const aboutCareerHobbies = JSON.parse(addMember);
      this.careerHobbiesForm.patchValue({
        hobbies: aboutCareerHobbies?.hobbies,
        careerAccomplishments: aboutCareerHobbies?.career_accomplishments
      });
    }
  }
  get hobbies() { return this.careerHobbiesForm.get('hobbies'); }
  get careerAccomplishments() { return this.careerHobbiesForm.get('careerAccomplishments'); }
  
  getMemberData(id) {
    this.memberService.getMemberStep(id, MagicNumbers.three).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const aboutCareerHobbies = res.data;
        this.careerHobbiesForm.patchValue({
          hobbies: aboutCareerHobbies?.hobbies == 'undefined' ? '': aboutCareerHobbies?.hobbies,
          careerAccomplishments: aboutCareerHobbies?.career_accomplishments == 'undefined' ? '': aboutCareerHobbies?.career_accomplishments,
        });      
      }
    });
  }

  getMemberDataHighlight(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const result = res.data;
        if(result.member_gallery.length!=0){
          this.updateHighlightMemories = true;
        } else{
          this.updateHighlightMemories = false;
        }
      }
    });
    this.memberService.getMemberStep(id, MagicNumbers.four).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const result = res.data;
        if(result.about_family != 'undefined'){
          this.updateHighlightFamily = true;
        } else{
          this.updateHighlightFamily = false;
        }   
      }
    });
  }

  /**
     * To navigate to next step and saving the data
    */
  onSubmit(action:string) {
    const countWord1 = document.getElementById('memorial1')?.innerText;
    const countWord2 = document.getElementById('memorial2')?.innerText;
    this.isSubmitted = true;
    if (this.careerHobbiesForm.valid) {
      const countWordLen1 = countWord1.trim().split(/\s+/).length;
      const countWordLen2 = countWord2.trim().split(/\s+/).length;
      if (countWordLen1 > MagicNumbers.threeHundred) {
        this.showMaxlength1 = true;
      }
      if (countWordLen2 > MagicNumbers.threeHundred) {
        this.showMaxlength2 = true;
      } else if (countWordLen1 <= MagicNumbers.threeHundred && countWordLen2 <= MagicNumbers.threeHundred) {

        // if(this.update){
          this.memberService.updateMember3(this.memberId, MagicNumbers.three, 
            { hobbies: this.careerHobbiesForm.value.hobbies, 
              career_accomplishments: this.careerHobbiesForm.value.careerAccomplishments }
            ).subscribe((res: IResponse) => {
            if (res && !res.errors) {
              if(action == 'saveNext'){
                this.router.navigate(['/about-family-memories', this.memberId]);
              } else{
                this.toastr.success('Details updated successfully.');
                this.router.navigate(['/view-information/',res.data.id]);
              }
            }
          });
      }
    } else {
      this.careerHobbiesForm.markAllAsTouched();
    }
  }
  /**
   * To navigate to previous step
  */
  goBack() {
    this.router.navigate(['/about-memorial', this.memberId]);
  }
  /**
   * To listen editor 1 changes event
  */
  editorChanges1(e) {
    this.showMaxlength1 = false;
  }
  /**
   * To listen the editor 2 changes event
  */
  editorChanges2(e) {
    this.showMaxlength2 = false;
  }
}
