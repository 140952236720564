<section>
    <div class="container add-member-container res-p-0">
        <form [formGroup]="careerHobbiesForm" (ngSubmit)="onSubmit(action)">
            <div class="main-container">
                <div class="add-member-wrapper wrapper-w-custom">
                    <div class="header-img-infinity">
                        <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity">
                    </div>
                    <div class="header-img-mobile ">
                        <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
                    </div>
                    <div class="footer-img-heart">
                        <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart">
                    </div>
                    <div class="footer-img-mobile ">
                        <img src="./../../../../assets/images/footer-heart-brown.svg" alt="footer heart image">
                    </div>
                    <div class="page-btn-wrap">
                        <div [routerLink]="memberId !== undefined ? ['/update-info', memberId] : '/update-info'"
                            class="add-detail active">
                            <span class="add-detail-number">1</span>
                            <div class="add-detail-text ">Loved One’s Details</div>
                        </div>
                        <div [routerLink]="memberId !== undefined ? ['/about-memorial', memberId] : '/about-memorial'"
                            class="add-detail active">
                            <span class="add-detail-number">2</span>
                            <div class="add-detail-text ">About Memorial</div>
                        </div>
                        <div class="add-detail active">
                            <span class="add-detail-number">3</span>
                            <div class="add-detail-text ">Hobbies & Career</div>
                        </div>
                        <div [routerLink]="updateHighlightFamily == false ? null : ['/about-family-memories', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightFamily == true}">
                            <span class="add-detail-number">4</span>
                            <div class="add-detail-text ">About Family</div>
                        </div>
                        <div [routerLink]="updateHighlightMemories == false ? null : ['/add-pictures-videos', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightMemories == true}">
                            <span class="add-detail-number">5</span>
                            <div class="add-detail-text ">Add Memories</div>
                        </div>
                    </div>
                    <div class="add-member-header">
                        <div>
                            <h1>{{updateHighlightMemories?"Edit Hobbies & Career details":"Add Hobbies & Career details"}}</h1>
                            <p>This information will be the published on the memorial.</p>
                        </div>
                        <div class="page-number-text">
                        </div>
                    </div>
                    <div class="about-memorial-section">
                        <div class="sub-heading">
                            <h5>Hobbies</h5>
                            <p>Word Limit: 300 Words</p>
                        </div>
                        <editor [apiKey]="tinyMceKey" (onKeyUp)="editorChanges1($event)" formControlName="hobbies"
                            [init]="{
                        height: 500,
                        menubar: false,
                        branding:false,
                    statusbar:false,
                    placeholder: 'Type here...',
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
                      }"></editor>
                        <p [innerHTML]="careerHobbiesForm.value.hobbies" [hidden]="true" id="memorial1"></p>
                        <ng-container *ngIf="(isSubmitted && hobbies?.invalid) && (hobbies?.dirty || hobbies?.touched)">
                            <span class="invalid-input-text mt-0" *ngIf="hobbies?.errors?.required">Hobbies are
                                required</span>

                        </ng-container>
                        <span class="invalid-input-text mt-0" *ngIf="showMaxlength1">Hobbies should not be
                            greater than 300 words
                        </span>
                    </div>
                    <div class="about-memorial-section">
                        <div class="sub-heading">
                            <h5>Career & Accomplishments</h5>
                            <p>Word Limit: 300 Words</p>
                        </div>
                        <editor [apiKey]="tinyMceKey" (onKeyUp)="editorChanges2($event)"
                            formControlName="careerAccomplishments" [init]="{
                        height: 500,
                        menubar: false,
                        branding:false,
                    statusbar:false,
                    placeholder: 'Type here...',
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
                      }"></editor>
                        <p [innerHTML]="careerHobbiesForm.value.careerAccomplishments" [hidden]="true" id="memorial2">
                        </p>
                        <ng-container
                            *ngIf="(isSubmitted && careerAccomplishments?.invalid) && (careerAccomplishments?.dirty || careerAccomplishments?.touched)">
                            <span class="invalid-input-text mt-0" *ngIf="careerAccomplishments?.errors?.required">Career
                                &
                                Accomplishments
                                are required
                            </span>
                        </ng-container>
                        <span class="invalid-input-text mt-0" *ngIf="showMaxlength2">Career &
                            Accomplishments
                            should not be greater than 300 words</span>

                    </div>
                </div>
            </div>
            <div class="member-buttons text-center">
                <button type="button" class="btn btn-outline-primary extra-padding" (click)="goBack()">Back</button>
                <button type="submit" (click)="action='saveNext'" class="btn btn-primary extra-padding">Save &
                    Next</button>
                <button
                    *ngIf="updateHighlightMemories && careerHobbiesForm.value.hobbies !== 'undefined' && careerHobbiesForm.value.hobbies !== ''"
                    type="submit" (click)="action='saveFinish'" class="btn btn-primary extra-padding">Save &
                    Finish</button>
            </div>
        </form>
    </div>
</section>
