import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../interfaces/response';
import { CommonService } from '../../services/common.service';
import { PaymentService } from '../../services/payment.service';
import { LandingPageService } from '../../services/landing-page.service';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { MagicNumbers } from '../../component/app-magic-numbers';

@Component({
  selector: 'app-manage-card',
  templateUrl: './manage-card.component.html',
  styleUrls: ['./manage-card.component.scss']
})
export class ManageCardComponent implements OnInit {
  stripe: Stripe;
  stripeElements: StripeElements;
  public billingInfo = false;
  public isPaymentSuccess = false;
  public paymentForm: FormGroup;
  public today = new Date();
  planCancelledOrNotActivated = true;
  memberId?: any;
  cancelledSubs;
  public basicDetails;
  public paymentCreatedDetails;
  public bodyElement;
  public submitted = false;
  public disablePaymentBtn = false;
  cardData;
  expYear: any;
  expDate;

  constructor(public ref: ChangeDetectorRef, public formBuilder: FormBuilder, 
    public commonService: CommonService, public modalRef: BsModalRef, public landingPageService: LandingPageService,
    public toastr: ToastrService, public loader: NgxSpinnerService, public paymentService: PaymentService) {
    

    this.paymentForm = this.formBuilder.group({
      cardHolderName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      cardNumber: ['', [Validators.required, Validators.pattern('^\\d{4} \\d{4} \\d{4} \\d{4}$')]],
      cardExpiry: ['', Validators.required],
      cardCvv: ['', [Validators.required, Validators.minLength(MagicNumbers.three), Validators.maxLength(MagicNumbers.four)]],
    });
  }
  get cardHolderName() {
    return this.paymentForm.get('cardHolderName');
  }

  get cardNumber() {
    return this.paymentForm.get('cardNumber');
  }

  get cardExpiry() {
    return this.paymentForm.get('cardExpiry');
  }

  get cardCvv() {
    return this.paymentForm.get('cardCvv');
  }

  formatCardNumber(event: any): void {
    const input = event.target.value.replace(/\D/g, '').substring(0, MagicNumbers.sixteen); // Remove all non-digit characters and limit to 16 digits
    const formattedInput = input.match(/.{1,4}/g)?.join(' ') || ''; // Insert space every 4 digits
    this.paymentForm.controls.cardNumber.setValue(formattedInput, { emitEvent: false });
  }



   


 
  

  ngOnInit(): void {
    this.loader.show();



    if (this.memberId) {
      // this.memberId = this.modalRef.content\.memberId;
      this.getSavedCard(this.memberId);
      // Now you can use this.memberId in your modal functions
    }
  }
  /**
  * To set the calendar month view 
 */

  getSavedCard(memberId: number) {
    this.landingPageService.getSavedCard(memberId).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.cardData = res.data;
      }
    });
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }
  /**
  * API Call for Submiting the payment details
 */

  saveCard() {
    this.submitted = true;
  
    if (this.paymentForm.valid) {
      // Get the expiry date value
      const expDateValue = this.paymentForm.value.cardExpiry;
  
      // Check if expDateValue is a Date object or a string
      let expMonth: number;
      let expYear: number;
  
      if (expDateValue instanceof Date) {
        // If it's a Date object
        expMonth = expDateValue.getMonth() + 1; // getMonth() returns month index (0-11)
        expYear = expDateValue.getFullYear(); // Get full year
      } else if (typeof expDateValue === 'string') {
        // If it's a string in the format 'MM/YY'
        const expDateParts = expDateValue.split('/');
        if (expDateParts.length === MagicNumbers.two) {
          expMonth = Number(expDateParts[0]);
          expYear = MagicNumbers.twoThousand + Number(expDateParts[1]); // Assuming YY is 20YY
        } else {
          this.paymentForm.markAllAsTouched(); // Mark all as touched if the value is not valid
          return; // Exit if the format is invalid
        }
      } else {
        this.paymentForm.markAllAsTouched(); // Mark all as touched if the value is not valid
        return; // Exit if the value is not valid
      }
  
      const data = {
        memberId: this.memberId, // Assuming memberId is available in your component
        cardNumber: this.paymentForm.value.cardNumber.replace(/ /g, ''), // Remove spaces
        expMonth: expMonth, // Use extracted month
        expYear: expYear, // Use extracted year
        cardCvv: this.paymentForm.value.cardCvv,
        // id: this.cardData.id
      };
  
      this.paymentService.createCardFromToken(data).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.modalRef.hide();
        } else {
          this.toastr.error(res.message);
          this.loader.hide();
          this.paymentForm.markAllAsTouched();
        }
      });
  
    } else {
      this.paymentForm.markAllAsTouched();
    }
  }
  
  
  


  /**
  * To add the class for expiry date picker
 */
  myFunction() {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', 'expiryDate bs-datepicker theme-green');
    }, 0);
  }
}
