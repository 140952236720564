<div class="signin-modal-content change-password-content upgrade-modal-content upgrade-modal-content2">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body signin-body change-password-body upgrade-plan2-body payment-success-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/upgrade-header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <div class="signin-container">
                <span class="para-color-text upgrade-plan2-heading">Existing Card</span>
                <div class="current-plan">
                    <div class="memory-code-order">
                        <p class="visa-title">{{cardData?.brand | uppercase}} ending in {{cardData?.last4}}</p>
                        <div class="memory-code-order-data">
                            <p class="visa-expire">Expires on
                                {{cardData?.exp_month}}/{{cardData?.exp_year?.toString()?.slice(-2)}}</p>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <div class="row upgrade-plan-row">
                        <div class="col-12">
                            <form class="add-member-form" [formGroup]="paymentForm" (ngSubmit)="saveCard()">
                                <span class="para-color-text upgrade-plan2-heading">Update Card</span>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-floating add-member-floating"
                                            [ngClass]="cardHolderName?.invalid && (cardHolderName?.dirty || cardHolderName?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                placeholder="Name On Card" formControlName="cardHolderName"
                                                maxlength="20">
                                            <label for="floatingInput">Name On Card</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cardHolderName?.invalid && (cardHolderName?.dirty || cardHolderName?.touched)">
                                            <span class="invalid-input-text"
                                                *ngIf="cardHolderName?.errors?.required">Card Name is required</span>
                                            <span class="invalid-input-text"
                                                *ngIf="cardHolderName?.errors?.pattern">Card Name must contain
                                                characters only</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-floating add-member-floating"
                                            [ngClass]="cardNumber?.invalid && (cardNumber?.dirty || cardNumber?.touched) ? 'invalid-input' : ''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                appNumbersonly maxlength="19" placeholder="Card Number"
                                                formControlName="cardNumber" (input)="formatCardNumber($event)">
                                            <label for="floatingInput" class="stripe-label">Card Number</label>
                                        </div>
                                        <span class="invalid-input-text "
                                            *ngIf="cardNumber?.invalid && (cardNumber?.dirty || cardNumber?.touched)">
                                            Please enter a valid card number
                                        </span>
                                    </div>
                                </div>
                                <div class="row expires-input margin-bottom-0">
                                    <div class="col-xl-6 col-md-6 col-12 payment-card-input">
                                        <div class="form-floating add-member-floating form-control-birth"
                                            [ngClass]="cardExpiry?.invalid && (cardExpiry?.dirty || cardExpiry?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                placeholder="Expiry Date" formControlName="cardExpiry"
                                                (onShown)="onOpenCalendar($event)" readonly bsDatepicker
                                                (click)="myFunction()" placement="bottom"
                                                [bsConfig]="{showWeekNumbers: false,dateInputFormat: 'MM/YY'}"
                                                [minDate]="today">
                                            <label for="birthDate" class="stripe-label">Expiry Date</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cardExpiry?.invalid && (cardExpiry?.dirty || cardExpiry?.touched)">
                                            <span class="invalid-input-text" *ngIf="cardExpiry?.errors?.required">
                                                Expiry Date is required
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-floating add-member-floating ccv-m-0"
                                            [ngClass]="cardCvv?.invalid && (cardCvv?.dirty || cardCvv?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                appNumbersonly maxlength="4" placeholder="CVV"
                                                formControlName="cardCvv">
                                            <label for="floatingPassword" class="stripe-label">CVV</label>
                                        </div>
                                        <ng-container *ngIf="cardCvv?.invalid && (cardCvv?.dirty || cardCvv?.touched)">
                                            <span class="invalid-input-text" *ngIf="cardCvv?.errors?.required">Card CVV
                                                is required</span>
                                            <span class="invalid-input-text" *ngIf="cardCvv?.errors?.minlength">Card CVV
                                                must be at least 3 digits</span>
                                            <span class="invalid-input-text" *ngIf="cardCvv?.errors?.maxlength">Card CVV
                                                cannot exceed 4 digits</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row pay-btn-row">
                                    <div class="col-xl-12 col-md-12 col-12 payment-order-btn">
                                        <button type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </ng-container>
                <span class="signin-footer"><img src="assets/images/upgrade-footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>
            </div>
        </div>
    </div>
</div>
