<section>
    <div class="container add-member-container res-p-16">
        <div class="main-container">
            <div class="add-member-wrapper picture-video-wrapper">
                <div class="header-img-infinity">
                    <img src="assets/images/add-pic-infinity.svg" alt="viewinfo-infinity">
                </div>
                <div class="header-img-mobile ">
                    <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
                </div>
                <div class="footer-img-heart">
                    <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart.svg">
                </div>
                <div class="footer-img-mobile more-bottom">
                    <img src="./../../../../assets/images/footer-heart-brown.svg" alt="footer heart image">
                </div>
                <div class="page-btn-wrap">
                    <div [routerLink]="memberId !== undefined ? ['/update-info', memberId] : '/update-info'"
                        class="add-detail active">
                        <span class="add-detail-number">1</span>
                        <div class="add-detail-text ">Loved One’s Details</div>
                    </div>
                    <div [routerLink]="memberId !== undefined ? ['/about-memorial', memberId] : '/about-memorial'"
                        class="add-detail active">
                        <span class="add-detail-number">2</span>
                        <div class="add-detail-text ">About Memorial</div>
                    </div>
                    <div [routerLink]="memberId !== undefined ? ['/about-careers-hobbies', memberId] : '/about-careers-hobbies'"
                        class="add-detail active">
                        <span class="add-detail-number">3</span>
                        <div class="add-detail-text ">Hobbies & Career</div>
                    </div>
                    <div [routerLink]="memberId !== undefined ? ['/about-family-memories', memberId] : '/about-family-memories'"
                        class="add-detail active">
                        <span class="add-detail-number">4</span>
                        <div class="add-detail-text ">About Family</div>
                    </div>
                    <div class="add-detail active">
                        <span class="add-detail-number">5</span>
                        <div class="add-detail-text ">Add Memories</div>
                    </div>
                </div>
                <div class="add-member-header add-pic-video-header">
                    <div class="section-title">
                        <h1>{{updateHighlight?"Edit Memories":"Add Memories"}}</h1>
                        <button class="btn upgrade-plan-btn" *ngIf="userData?.user?.get_plan?.length===2">Upgraded
                            Plan</button>
                    </div>
                    <p class="add-pic-text">{{updateHighlight?"Edit your Memories & Create an Album.":"Add your Memories & Create
                        an Album."}}</p>

                    <div class="page-number-text">
                    </div>
                </div>
                <!-- file input section -->
                <div class="about-memorial-section file-input-section res-pb-0">
                    <div class="add-pic add-pic-flax-justify res-mt-0">
                        <div class="row-heading">{{pictureCount}}/{{uploadLimit?.image_upload_allowed}} pictures added
                            <span class="picture-size-add">(jpeg, png, and jpg only - upto 10 mb)</span>
                        </div>
                        <div class="add-end-add-pic">
                            <!-- <div class="margin-right-30" *ngIf="(uploadLimit?.image_upload_allowed == 250)">
                                <a class="ms-auto"
                                    *ngIf="pictureCount < uploadLimit?.image_upload_allowed"
                                    [hidden]="pictureCount>=uploadLimit?.image_upload_allowed"><img
                                        src="assets/images/add-picture-logo.svg" alt="Add Picture" />
                                    <div class="pic-video-file-input">
                                        <label for="formFile" class="form-label">Add Picture</label>
                                        <input class="form-control" type="file" id="formFile" placeholder="Add Picture"
                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                            #otherImage (change)="fileSelected($event,'image')">
                                    </div>
                                </a>
                            </div> -->

                            <div class="margin-right-30" *ngIf="!(uploadLimit?.image_upload_allowed == 250)">
                                <a class="ms-auto"><img src="assets/images/add-picture-logo.svg" alt="Add Picture" />
                                    <div class="pic-video-file-input">
                                        <label for="formFile" class="form-label">Add Picture</label>
                                        <input class="form-control" type="file" id="formFile" placeholder="Add Picture"
                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                            #otherImage (change)="fileSelected($event,'image')">
                                    </div>
                                </a>
                            </div>
                            <!-- <div *ngIf="(uploadLimit?.video_upload_allowed == 10)">
                                <a
                                    [ngClass]="pictureCount===uploadLimit?.image_upload_allowed?'ms-auto':''"
                                    *ngIf="videoCount < uploadLimit?.video_upload_allowed"
                                    [hidden]="videoCount>=uploadLimit?.video_upload_allowed"><img
                                        src="assets/images/add-video-logo.svg" alt="Add Video" />
                                    <div class="pic-video-file-input">
                                        <label for="formFile" class="form-label">Add Video</label>
                                        <input class="form-control" type="file" id="formFile" placeholder="Add Video"
                                            (change)="fileSelected($event,'video')">
                                    </div>
                                </a>
                            </div> -->
                            <div *ngIf="!(uploadLimit?.video_upload_allowed == 10)">
                                <a class="ms-auto"><img src="assets/images/add-video-logo.svg" alt="Add Video" />
                                    <div class="pic-video-file-input">
                                        <label for="formFile" class="form-label">Add Video</label>
                                        <input class="form-control" type="file" id="formFile" placeholder="Add Video"
                                            (change)="fileSelected($event,'video')">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="file-input-wrapper">
                        <!-- file input section disable -->
                        <div class="row edit-card-row">
                            <div class="col-6 col-md-3">
                                <div [hidden]="pictureCount>=uploadLimit?.image_upload_allowed"
                                    class="custom-file-input file-bg-white">
                                    <span>Add Picture</span>
                                    <input class="form-control z-index" type="file" id="formFile"
                                        accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                        (change)="fileSelected($event,'image')" />
                                </div>
                            </div>
                            <div class="col-6 col-md-3 add-picture-col" *ngFor="let picture of pictureArr;let i=index">
                                <div *ngIf="picture?.path !==''" class="custom-file-input file-bg-white"
                                    [ngClass]="picture?.path ? 'removeBgIcon' : ''"
                                    [style.border]="picture?.path!==''?'none':''">
                                    <img [src]="picture?.path" [alt]="picture?.path ===''?'eternallyme image':''"
                                        class="upload-pic" (click)="staticModal.show();previewImage=picture?.path" />
                                    <span *ngIf="picture?.path===''">Add Picture</span>
                                    <input *ngIf="picture?.path===''" class="form-control z-index" type="file"
                                        id="formFile"
                                        accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                        (change)="fileSelected($event,'image')" />

                                    <button type="button" class="btn btn-link delete-img-btn"
                                        *ngIf="picture?.path!=='' && pictureArr.length!==1"><img
                                            src="./../../../assets/images/delete-img-icon.svg" (click)="removeMemory=i"
                                            alt="delete-img"></button>

                                    <div class="reomove-modal-wrap" *ngIf="removeMemory===i">
                                        <div class="reomove-modal-sec">
                                            <div class="reomove-title">Remove Memory?</div>
                                            <button type="submit" class="btn btn-primary-btn fill-button reomove-btn"
                                                (click)="deleteImage(i)">Yes, Remove</button>
                                            <div><a class="cancel-modal-btn" (click)="removeMemory=-1">Cancel</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- file input picture enable -->
                        <ng-container>
                            <div class="row-test">
                                <p class="row-heading row-top-margin">
                                    {{videoCount}}/{{uploadLimit?.video_upload_allowed}} videos added <span
                                        class="picture-size-add">(MP4, WebM, MOV & AVI only - upto 20 mb)</span></p>
                                <div class="row video-wrapper">
                                    <div class="col-6 col-md-3 add-picture-col"
                                        [hidden]="videoCount>=uploadLimit?.video_upload_allowed">
                                        <div class="custom-file-input custom-video file-bg-white">
                                            <span>Add Video</span>
                                            <input class="form-control z-index" type="file" id="formFile"
                                                (change)="fileSelected($event,'video')" />
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 add-picture-col"
                                        *ngFor="let video of videosArr;let i=index" [hidden]="video===''">
                                        <div class="custom-file-input custom-file-input-enable add-video active"
                                            *ngIf="video?.path">
                                            <video (click)="showVideo(video?.path)" [src]="video?.path + '#t=0.001'"
                                                height="100px" width="100px" *ngIf="video?.path!==''"
                                                class="upload-video view-all-memory-img">
                                                <track label="English" kind="captions" srclang="en" src="" default>
                                            </video>
                                            <div class="reomove-modal-wrap" *ngIf="removeMemoryVideo===i">
                                                <div class="reomove-modal-sec">
                                                    <div class="reomove-title">Remove Memory?</div>
                                                    <button type="submit"
                                                        class="btn btn-primary-btn fill-button reomove-btn"
                                                        (click)="deleteVideo(i)">Yes, Remove</button>
                                                    <div><a class="cancel-modal-btn"
                                                            (click)="removeMemoryVideo=-1">Cancel</a></div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-link delete-img-btn"
                                                *ngIf="video?.path!==''"><img
                                                    src="./../../../assets/images/delete-img-icon.svg"
                                                    (click)="removeMemoryVideo=i" alt="delete-img"></button>
                                            <div (click)="showDoc(video?.path)" class="video-icon"
                                                *ngIf="video?.path!==''"><img
                                                    src="./../../../assets/images/video-img.svg"
                                                    alt="eternallyme video image" class="video-icon-img" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <p class="note" *ngIf="userData?.user?.get_plan?.length !==2 && dayCount < 15">Note: Pictures
                            will be editable for {{15-dayCount}} {{dayCount===14?'day':'days'}} after submitting the
                            form
                        </p>
                    </div>
                    <div class="upload-prayer-card-title">Add Prayer Card</div>
                    <div class="add-prayer-card-wrapper">
                        <div class="prayer-card-view" *ngIf="prayerCard">
                            <div class="prayer-card-logo">
                                <img class="prayer-close" *ngIf="showType!='pdf'" [src]="prayerCard" alt="images">
                                <img class="prayer-close" *ngIf="showType=='pdf'" src="./../../../assets/images/pdf.png"
                                    alt="image">
                            </div>
                            <div class="add-img-del">
                                <img class="border-none" src="assets/images/icon-del-col.svg" alt="cross"
                                    (click)="prayerCard=''" />
                            </div>
                        </div>
                    </div>
                    <div class="upload-prayer-card-sec" *ngIf="!prayerCard">
                        <div class="upload-prayer-title">Upload Prayer Card</div>
                        <div class="upload-prayer-subtitle">Drag & Drop your image or <a href="#">click here</a> to
                            select</div>
                        <input class="custom-input-file" type="file" id="logoUpload" placeholder="Add Prayer card"
                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG,application/pdf"
                            #otherImage (change)="uploadPrayerCard($event)">
                        <div class="upload-prayer-subtitle">(JPEG, PNG, PDF, Max 5 MB )</div>
                    </div>
                    <p class="upgrade-plan-text" *ngIf="standardPlan">Want to add more Picture Memories and Upload
                        Videos about your loved ones? <a (click)="openUpgradePlan()">Upgrade Your
                            Plan</a> to view further details.
                    </p>

                </div>
            </div>
        </div>

        <div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
            tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
            <div class="modal-dialog modal-sm image-preview-modal-dialog">
                <div class="modal-content image-preview-modal-content">
                    <div class="modal-body">
                        <div class="image-preview-wrapper">
                            <img [src]=" previewImage" alt="picture" class="image-preview img-fluid" />
                        </div>
                        <button type="button" class="btn btn-link image-preview-close-btn"
                            (click)="staticModal.hide()"><img src="assets/images/image-preview-close.svg"
                                alt="close-btn" class="img-fluid" /></button>
                    </div>
                </div>
            </div>
        </div>
        <div class=" member-buttons text-center">
            <button type="button" class="btn btn-outline-primary extra-padding" (click)="goBack()">Back</button>
            <button type="button" class="btn btn-primary extra-padding " (click)="onNext()">{{updateHighlight?'Save &
                Finish':'Submit'}}</button>
        </div>
    </div>
</section>
