import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpgradeAccountComponent } from '../../common/modal/upgrade-account/upgrade-account.component';
import { AddGuestbookService } from '../../common/services/add-guestbook.service';
import { CommonService } from '../../common/services/common.service';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-guestbook-request',
  templateUrl: './guestbook-request.component.html',
  styleUrls: ['./guestbook-request.component.scss'],
})
export class GuestbookRequestComponent implements OnInit,AfterViewInit {
  @ViewChild('rejectedBtn', { static: false }) rejectedButton!: ElementRef;
  public messageRequestList: any[];
  public memberID: any;
  public showAll : boolean;
  public showPicture : boolean;
  public showMessage : boolean;
  public showPending : boolean;
  public showAccepted : boolean;
  public showRejected : boolean;
  public mediaRequestList: any[];
  public userData;
  public destroy$ = new Subject();
  public previewImage: string;
  public uploadLimit: any;
  public showUpgrade: boolean;
  standardPlan = true;
  public className = 'signin-modal';
  totalAccepted =  0;
  totalRejected = 0;
  totalPending = 0;

  constructor(
    public addGuestbookService: AddGuestbookService,
    public commonService: CommonService,
    public modalService: BsModalService,
    public toastr: ToastrService, public commonStateService: CommonService,
    public activatedRoute: ActivatedRoute
    ) {
    this.showAll = true;
    this.showPicture = true;
    this.showMessage = true;
    this.showPending = false;
    this.showAccepted = true;
    this.showRejected = true;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberID = params.id;
      }
    });
    // To listen the user data
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
        this.showUpgrade = this.userData?.user?.get_plan?.length===1 ? true : false;
      }
    });
    if(this.memberID){
      this.getAllowMediaLimit();
      this.getMessageRequestList(0);
      this.getMediaRequestList('', 0);
      this.calculateTotal();
      this.previewImage='';
    }
    this.commonStateService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
      if(this.userData?.user?.plan_type === 1){
        this.standardPlan = true;
      } else if(this.userData?.user?.plan_type === MagicNumbers.two){
        this.standardPlan = false;
      }
      } else {
      }
    });
  }

  ngAfterViewInit(){
    this.commonService.mediaApiCall.subscribe((res)=>{
      this.showUpgrade = false;
      this.getAllowMediaLimit();
      this.getMediaRequestList('', 0);
    });

  }

  /**
   * API Call to get message list
   */
  getMessageRequestList(status?) {
    if(this.showPending == false){
      status = 0;
    } else if(this.showAccepted == false){
      status = 1;
    } else if(this.showRejected == false){
      status = 2;
    }
    this.messageRequestList = [];
    const payloads = {
      status: status,
      member_id: this.memberID,
    };
    this.addGuestbookService.getMessageRequestList(payloads).subscribe((res: any) => {
      if (res) {
        this.messageRequestList = res.data.data;
      }
    });
  }

    /**
   * API Call to get message list
   * type blank string means all picture and video
   */
    getMediaRequestList(Type:any,status?) {
      if(this.showPending == false){
        status = 0;
      } else if(this.showAccepted == false){
        status = 1;
      } else if(this.showRejected == false){
        status = 2;
      }
      this.mediaRequestList = [];
      const payloads = {
        status:status,
        member_id: this.memberID,
        type : Type,
        source :'detail_page'
      };
      this.addGuestbookService.getMediaRequestList(payloads).subscribe((res: any) => {
        if (res) {
          this.mediaRequestList = res.data.data || [];
        }
      });
    }

    /**
   * API Call to update the status
   * status is 1 or 0
   * id that we are updating
   */
  statusUpdated(Status,ID){
    const payloads = {
      id: ID,
      status : Status
    };
    this.addGuestbookService.updateGuestbookRequest(payloads).subscribe((res: any) => {
      if (res) {
        this.toastr.success(res.message);
        this.getMessageRequestList();
        this.calculateTotal();
      }
    });
  }

  calculateTotal(){
    let type;
    if(this.showMessage && this.showPicture){
      type = 0;
    } else if(this.showMessage && !this.showPicture){
      type = 1;
    } else{
      type = 2;
    }
    const payloads = {
      member_id: this.memberID,
      type : type
    };
    this.addGuestbookService.addGuestbookTotalCount(payloads).subscribe((res: any) => {
      if (res) {
        this.totalAccepted = res.data.accepted || 0;
        this.totalRejected = res.data.rejected || 0;
        this.totalPending = res.data.pending || 0 ;
      }
    });
  }

     /**
  * API Call to update the media status
  */
  mediaStatusUpdated(value,ID){
    const payloads = {
      id: ID,
      status : value
    };

    this.addGuestbookService.updateMediaGuestbookRequest(payloads).subscribe((res: any) => {
      if (res) {
        if(res.data.reason){
          this.toastr.error(res.message);
        }else{
        this.toastr.success(res.message);
        }
        this.mediaRequestList = [];
        this.getAllowMediaLimit();
        this.getMediaRequestList('');
        this.calculateTotal();
      }
    });
  }

  /**
  * To open the vide in new tab
  * @param video is response of list
  */
  showVideo(video: string) {
    window.open(video, '_blank');
  }

    /**
   * To open the upgrade plan modal
  */
    openUpgradePlan() {
      this.modalService.show(UpgradeAccountComponent, { class: this.className });
    }

  showSection(section,value){
    if(section === 'message'){
      this.showMessage = value;
      this.showPicture = false;
      this.showAll = false;
    }else if(section === 'All'){
      this.showMessage = value;
      this.showPicture = value;
      this.showAll = value;
    }else if(section === 'picture'){
      this.showPicture = value;
      this.showMessage = false;
      this.showAll = false;
    } else if(section === 'pending'){
      this.showPending = value;
      this.showAccepted = true;
      this.showRejected = true;
    } else if(section === 'accepted'){
      this.showPending = true;
      this.showAccepted = value;
      this.showRejected = true;
    } else if(section === 'rejected'){
      this.showPending = true;
      this.showAccepted = true;
      this.showRejected = value;
      setTimeout(() => {
        this.rejectedButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 0);
    }
  }

      /**
   * Method to call get media list
  */
  getAllowMediaLimit(){
    const payloads={
      member_id: this.memberID,
    };
    this.addGuestbookService.getAllowMediaLimit(payloads,'guest_approve').subscribe((res: any) => {
      if (res) {
        this.uploadLimit = res.data;
      }
  });
}
}
