<section>
    <div class="container">
        <div class="main-container add-info-padding">
            <div class="add-member-wrapper add-member-purchase">
                <div class="header-img-infinity">
                <img
                    src="assets/images/add-pic-infinity.svg"
                    alt="add-pic-infinity"
                />
                </div>
                <div class="footer-img-heart">
                <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart" />
                </div>
            

                <div class="purchase-package-section">
                    <div class="guestbook-title">Purchase a package for your loved one.</div>
                    <div class="guestbook-subtitle">After the $195 payment, you will be charged an annual membership in 90 days.</div>
                    <div class="package-sec-wrap">
                        <div class="package-sec package-sec-mob">
                            <div class="package-select">
                                <div class="package-select-text">Select your<br />Package. <img src="assets/images/icon-right-color.svg" alt="right arrow" /></div>
                                <div class="package-select-img"><img src="assets/images/picture-memory-0.png" alt="Image" /></div>
                            </div>
                        </div>
                        <div class="package-sec">
                            <div class="package-sec-content">
                                <div class="content-top">
                                    <div class="content-title">Limited Storage</div>
                                    <div class="content-subtitle">Standard Plan</div>
                                    <div class="content-dollor">$195</div>
                                    <div class="content-payment plan-color"><span class="content-payment-block">One Time Payment & Then</span> <span class="plan-dollor">$14.99</span> Annually</div>
                                    <div class="content-pic"><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet"> 250 Picture Memories</div>
                                    <div class="content-pic"><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet"> 10 Video Memories</div>
                                </div>
                                <div class="content-bottom">
                                    <button  type="button" class="btn btn-primary" (click)="openModal(qrCodeDelivery,1)">Select</button>
                                </div>
                            </div>
                        </div>
                        <div class="package-sec">
                            <div class="package-sec-content">
                                <div class="content-top">
                                    <div class="content-title">Value For Money</div>
                                    <div class="content-subtitle">Upgraded Plan</div>
                                    <div class="content-dollor">$195</div>
                                    <div class="content-payment plan-color"><span class="content-payment-block">One Time Payment & Then</span> <span class="plan-dollor">$24.99</span> Annually</div>
                                    <div class="content-pic"><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet"> Unlimited Picture Memories</div>
                                    <div class="content-pic"><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet"> Unlimited Video Memories</div>
                                </div>
                                <div class="content-bottom">
                                    <button type="button" class="btn btn-primary" (click)="openModal(qrCodeDelivery,2)">Select</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start QR Memory Code Delivery Modal -->

<ng-template #qrCodeDelivery>
            <div class="signin-modal-content change-password-content">
                <div class="modal-header signin-modal-header">
                    <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()"></button>
                </div>
                <div class="modal-body signin-body change-password-body having-issues-body">
                    <div class="signin-section">
                        <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                                alt="header-infinity" class="modal-header-img" /></span>
                        <div class="having-issues-title mb-20">QR Memory Code Delivery</div>
                        <div class="signin-container">
                            <form class="login-container change-password-container">
                                <div class="delivery-code-sec">
                                    <div class="check-code-sec">
                                        <div class="radio-box-sec">
                                            <input class="form-check-input" [disabled]="!showStatus" type="radio" name="delivery" (click)="getValue(1)" id="codenow">
                                            <label class="form-check-label" for="codenow">Deliver QR Memory Code Now</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="delivery-code-text">Your physical QR Memory code will be delivered within 2 days</div>
                                <div class="delivery-code-sec">
                                    <div class="check-code-sec">
                                        <div class="radio-box-sec">
                                            <input class="form-check-input" type="radio" name="delivery" (click)="getValue(2)" id="codelater">
                                            <label class="form-check-label" for="codelater">Deliver QR Memory Code Later</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="delivery-code-text">You'll get the QR Memory code and be able to create the memorial right away.
                                    The Physical QR Memory code will be delivered later after the manufacturing.</div>
                                <div class="delivery-code-sec">
                                    <div class="check-code-sec">
                                        <div class="radio-box-sec">
                                            <input class="form-check-input" type="radio" name="delivery" (click)="getValue(3)" id="memoryCode">
                                            <label class="form-check-label" for="memoryCode">Already Have The QR Memory Code</label>
                                        </div>
                                        <div class="input-memory-sec">
                                            <div class="form-floating margin-btm-0">
                                                <input type="text" class="form-control" id="name" [(ngModel)]="memoryCodeText" [disabled]="codeNow !== 3"
                                                    placeholder="Name" name="name" maxlength="30"
                                                    autocomplete="off" />
                                                <label for="name">Enter QR Memory Code</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="delivery-code-text">Enter the QR Memory code if you already have one & create the memorial page.</div>
                                <div class="form-action">
                                    <button class="btn btn-primary" type="submit" [disabled]="(!memoryCodeText && codeNow===3) || show" (click)="submit()">Continue</button>
                                </div>
                            </form>
                            <span class="signin-footer mb-4"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                                    class="modal-footer-img" /></span>
                        </div>
                    </div>
                </div>
            </div>
</ng-template>

<!-- End QR Memory Code Delivery Modal -->
