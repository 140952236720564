import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public apiUrl;
  constructor(public http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }
  getStates() {
    return this.http.get(`${this.apiUrl}common/states`);
  }
  getCities(stateId: number) {
    return this.http.get(`${this.apiUrl}common/cities?state_id=${stateId}`);
  }
  uploadImage(file, type) {
    return this.http.post(`${this.apiUrl}common/image-upload?base_path=${type}`, file);
  }
  updateMemberImage(id, proPicture) {
    return this.http.put(`${this.apiUrl}update_member_profile_picture?id=${id}&profile_picture=${proPicture}`, '');
  }
  uploadVideo(file, type) {
    return this.http.post(`${this.apiUrl}common/video-upload?base_path=${type}`, file);
  }
  submitForm(payloads) {
    const URL = this.apiUrl + 'broadcast/send-message';
    return this.http.post(URL, payloads);
  }
  createPaymentIntent(data) {
    const body = new HttpParams()
      .set('amount', data.amount)
      .set('currency', data.currency)
      .set('payment_method', data.id)
      .set('description', 'EternallyMe');
    return this.http.post('https://api.stripe.com/v1/payment_intents', body.toString());
  }
  checkPendingReq(memberID) {
    return this.http.get(`${this.apiUrl}user/guestbook/check-pending-request?member_id=${memberID}`);
  }
   exportGuestList(params?: any) {
    const timezone = moment.tz.guess(); // Get the local timezone
    const apiUrl = `${this.apiUrl}user/guestbook/export-guest-list?timezone=${timezone}`;
  
    let requestUrl = params ? `${apiUrl}&${params}` : apiUrl;
  
    this.http.get(requestUrl, {
      responseType: 'arraybuffer' // Expecting an array buffer response for file download
    }).subscribe(response => this.downLoadFile(response, "application/ms-excel", 'Guest list.xlsx'));
  }
  
  downLoadFile(data: any, type: string, filename: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a'); // Create an anchor element
    a.href = url;
    a.download = filename; // Set the file name
    document.body.appendChild(a); // Append it to the DOM
    a.click(); // Trigger download
    document.body.removeChild(a); // Clean up by removing the anchor element
    window.URL.revokeObjectURL(url); // Revoke the object URL to free memory
  }
   getUnsubscribe(token: any) {
    return this.http.get(`${this.apiUrl}unsubscribe/${token}`);
  }

  validatePlan(memoryCode: any) {
    return this.http.post(`${this.apiUrl}validate-qr-code`,{serial_number:memoryCode});
  }
  memoryStatus() {
    return this.http.get(`${this.apiUrl}check_qr_code_exist`);
  }

  addCoOwner(payloads){
    const URL = this.apiUrl + 'add-co-owner-details';
    return this.http.post(URL, payloads);
  }

  deleteOwnerFuneralAccount(payloads) {
    const URL = this.apiUrl + 'deactivate-account';
    return this.http.post(URL, payloads);
  }

  inviteCoOwners(payloads) {
    const URL = this.apiUrl + 'co-owner-create';
    return this.http.post(URL, payloads);
  }

  updateProfileImage(id, proPicture) {
    return this.http.put(`${this.apiUrl}update_user_profile_picture?id=${id}&profile_picture=${proPicture}`, '');
  }
}
