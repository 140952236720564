import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IResponse } from '../../common/interfaces/response';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { UpgradeAccountComponent } from '../../common/modal/upgrade-account/upgrade-account.component';
import { CommonService } from '../../common/services/common.service';
import { MemberService } from '../../common/services/member.service';
import { UtilityService } from '../../common/services/utility.service';

@Component({
  selector: 'app-add-pictures-videos',
  templateUrl: './add-pictures-videos.component.html',
  styleUrls: ['./add-pictures-videos.component.scss']
})
export class AddPicturesVideosComponent implements OnInit,AfterViewInit,OnDestroy {
  public videosArr = [];
  public destroy$ = new Subject();
  public addMember = {};
  public aboutMemorial = {};
  public familyMemories = {};
  public careerHobbies = {};
  public userData;
  public pictureArr = [{path:''}];
  public pictureCount = 0;
  public update = false;
  public prayerCard = "";
  public previewImage: string;
  
  public dayCount = 0;
  public planName;
  public videoCount = 0;
  type: any;
  showType: string='pdf';
  uploadLimit: any;
  public removeMemory :any;
  public removeMessage :any;
  public removeMemoryVideo : any;
  standardPlan = true;
  planCancelledOrNotActivated = false;
  public className = 'signin-modal';
  memberId: string;
  updateHighlight: boolean;


  constructor(public modalService: BsModalService, public router: Router, 
    public utilityService: UtilityService,public activatedRoute: ActivatedRoute,
    public toastr: ToastrService, public commonService: CommonService, public memberService: MemberService) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (localStorage.getItem('member_id')) {
      this.memberId = localStorage.getItem('member_id');
      this.update = true;
      this.getMemberData(this.memberId);
      this.getMemberDataHighlight(this.memberId);      
    }
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.update = true;
        this.getMemberData(this.memberId);       
      }
    });
    if (localStorage.getItem('update')) {
      this.update = true;
    }
    this.aboutMemorialService();
    this.familyMemoriesService();
    this.hobbiesCareerService();
    this.userDataService();
    this.previewImage = '';
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
      if(this.userData?.user?.plan_type === 1){
        this.standardPlan = true;
      } else if(this.userData?.user?.plan_type === MagicNumbers.two){
        this.standardPlan = false;
      }
      } else {
      }
    });
  }

  getMemberData(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const pictureVideoData = res.data;
        const pictures = pictureVideoData?.member_gallery;
      const videos = pictureVideoData?.member_gallery;
      this.pictureArrMember(pictures);
      this.videosArrMember(videos);

      this.planName = pictureVideoData?.plan;
      this.dayCount = pictureVideoData?.day_count;

        this.prayerCard = pictureVideoData.prayer_card ? pictureVideoData.prayer_card : '';
        if(this.prayerCard){
          if(this.prayerCard.includes('pdf')){
            this.showType = 'pdf';
          }else{
            this.showType = 'images';
          }
        }
      }
    });
  }

  ngAfterViewInit(){
    this.commonService.mediaApiCall.subscribe((res)=>{
      this.getAllowMediaLimit();
    });
  }

  getMemberDataHighlight(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const result = res.data;
        if(result.member_gallery.length!=0){
          this.updateHighlight = true;
        } else{
          this.updateHighlight = false;
        }
      }
    });
  }

  // To count number of pictures uploaded
  pictureArrMember(pictures: any) {
    if (pictures) {
        // Filter pictures of type 0
        const arr = pictures.filter(res => res.type == 0);

        // Use a Set to keep track of unique paths
        const uniquePaths = new Set<string>();

        // Filter out pictures with duplicate paths
        const uniqueArr = arr.filter(picture => {
            if (uniquePaths.has(picture.path)) {
                return false; // Skip this picture as its path is already added
            } else {
                uniquePaths.add(picture.path);
                return true; // Include this picture
            }
        });

        // Replace the content of pictureArr with unique pictures
        this.pictureArr.splice(0, this.pictureArr.length, ...uniqueArr);

        // Reset and calculate the new picture count
        this.pictureCount = 0;
        this.pictureArr.forEach(element => {
            if (element.path !== '') {
                this.pictureCount = this.pictureCount + 1;
            }
        });
    }
}

  // To count number of videos uploaded
  videosArrMember(videos) {
    if (videos) {
        // Filter videos of type 1
        const arr = videos.filter(res => res.type == 1);

        // Use a Set to keep track of unique paths
        const uniquePaths = new Set<string>();

        // Filter out videos with duplicate paths
        const uniqueArr = arr.filter(video => {
            if (uniquePaths.has(video.path)) {
                return false; // Skip this video as its path is already added
            } else {
                uniquePaths.add(video.path);
                return true; // Include this video
            }
        });

        // Replace the content of videosArr with unique videos
        this.videosArr.splice(0, this.videosArr.length, ...uniqueArr);

        // Reset and calculate the new video count
        this.videoCount = 0;
        this.videosArr.forEach(element => {
            if (element !== '') {
                this.videoCount = this.videoCount + 1;
            }
        });
    }
}

  // To listen the add member data
  addMemberService(){
    this.commonService.addMember.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.addMember = res;
      }
    });
  }

   // To listen the about memorial data
  aboutMemorialService(){
    this.commonService.aboutMemorial.pipe(takeUntil(this.destroy$)).subscribe(res => {
     if (res) {
       this.aboutMemorial = res;
    }
    });
  }
  
  // To listen the hobbies career data
  hobbiesCareerService(){
    this.commonService.hobbiesCareer.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.careerHobbies = res;
      }
    });
  }

  // To listen the family memories data
  familyMemoriesService(){
    this.commonService.familyMemories.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.familyMemories = res;
      }
    });
  }

  // To listen the user data
  userDataService(){
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
       this.getAllowMediaLimit();
      }
    });
  }
  /**
   * To open the upgrade plan modal
  */
  openUpgradePlan() {
    this.modalService.show(UpgradeAccountComponent, { class: this.className });
  }
  /**
   * To upload the image or video
  */
  fileSelected(event, type?) {
    const fd = new FormData();
    fd.append(event.target.files[0].type.split('/')[0], event.target.files[0]);
    const uploadType = event.target.files[0].type.split('/')[0];
      if (uploadType !== type) {
        this.toastr.error(`Please upload ${type}`);
      } else if (event.target.files[0].size >= MagicNumbers.MbImageSize && type === 'image') {
        this.toastr.error(`Please upload less than 10 mb ${uploadType}`);
      }else if (event.target.files[0].size >= MagicNumbers.MbSize && type === 'video') {
        this.toastr.error(`Please upload less than 20 mb ${uploadType}`);
      } else {
          this.utilityService[uploadType === 'image' ? 'uploadImage' : 'uploadVideo'](fd, uploadType === 'image' ? 'other' : 'video').subscribe((res: IResponse) => {
            if (res && !res.errors) {
              this.toastr.success(`${uploadType === 'image' ? 'Image' : 'Video'} uploaded successfully`);
              if (uploadType === 'image') {
                const index = this.pictureArr.findIndex((e) => e.path === '');
                if (index === -1) {
                  this.pictureArr.push({path : res.data.url});
                } else {
                  this.pictureArr[index] = {path : res.data.url};
                }
                this.pictureCount = 0;
                this.pictureArr.forEach((e) => {
                  if (e.path !== '') {
                    this.pictureCount = this.pictureCount + 1;
                  }
                });
              } else {
                const index = this.videosArr.findIndex((e) => e === '');
                if (index === -1) {
                  this.videosArr.push({path : res.data.url});
                } else {
                  this.videosArr[index] = {path : res.data.url};
                }
                this.videoCount = 0;
                this.videosArr.forEach((e) => {
                  if (e !== '') {
                    this.videoCount = this.videoCount + 1;
                  }
                });
            }
            }
            event.target.value = '';
          }); 
      }
    // }
  }



    /**
   * To upload the image or video
  */
  uploadPrayerCard(event) {
    this.type = 'prayer_card';
    const fd = new FormData();
    if(event.target.files[0].type.split('/')[0]==='image'){
      fd.append(event.target.files[0].type.split('/')[0], event.target.files[0]);
     this.showType = 'image';

    }else{
      fd.append('image', event.target.files[0]);
      this.showType = 'pdf';
    }
    const uploadType = event.target.files[0].type.split('/')[0]; 
      if (event.target.files[0].size >= MagicNumbers.MbPrayerCardSize) {
        this.toastr.error(`Please upload less than 5 mb ${uploadType}`);
      } else {
        this.utilityService.uploadImage(fd, this.type).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success(res.message);
              this.prayerCard = res.data.url;
              localStorage.setItem('prayerCard',this.prayerCard);
              localStorage.setItem('showType',this.showType);
          }
          event.target.value = '';
        });
    }
  }
  /**
     * To submit the all details to form
    */
  onNext() {
    const checkAll = this.pictureArr.every((e) => e.path === '');
    const pictures = [];
    const videos = [];
    if (!checkAll) {
      this.pictureArr.forEach((e) => {
        if (e.path !== '') {
          pictures.push(e.path);
        }
      });
      this.videosArr.forEach((e) => {
        if (e !== '') {
          videos.push(e.path);
        }
      });
      const mediaData=
      {
        other_picture: JSON.stringify(pictures), 
        video: JSON.stringify(videos),
        prayer_card: this.prayerCard
      };
        this.memberService.updateMember5(this.memberId, MagicNumbers.five, mediaData).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success('Details updated successfully.');
            this.router.navigate(['/view-information/',res.data.id]);


            const user = localStorage.getItem('userData');
            const storeUser = JSON.parse(user);
            storeUser.user.member = res.data;
            localStorage.setItem('userData', JSON.stringify(storeUser));
            this.commonService.updateMemoryStatus.next(true);
          }
        });
    } else {
      this.toastr.error('Please upload atleast one picture');
    }
  }

  updatedSuccessfull(update,res){
    if (update) {
      this.toastr.success('Updated successfully');      
      localStorage.removeItem('update');
      localStorage.removeItem('addMember');
      localStorage.removeItem('showType');
      localStorage.removeItem('prayerCard');
      this.router.navigate(['/view-information/',res.data.id]);
    } else {
      this.router.navigate(['/congratulations'], { queryParams: { data: JSON.stringify(res.data) } });
    }
  }
  /**
   * To navigate to previous step
  */
  goBack() {
    this.router.navigate(['/about-family-memories', this.memberId]);
  }
  /**
   * To delete the image
  */
  deleteImage(index: number) {
    if(this.pictureArr.length===1){
      this.pictureArr.splice(index, 1,{path:''});
    }else{
      this.pictureArr.splice(index, 1);
    }
    if (this.userData?.user?.get_plan?.length !== MagicNumbers.two) {
    }
    this.pictureCount = Number(this.pictureCount) - 1;
    const addMember = localStorage.getItem('addMember');
    let addData = JSON.parse(addMember);
    const picture = this.pictureArr.map(res=> res.path);
    addData = { ...addData, ...{ other_picture: JSON.stringify(picture) } };
    localStorage.setItem('addMember', JSON.stringify(addData));
    this.removeMemory =-1;
  }
  /**
   * To delete the video
  */
  deleteVideo(i: number) {
    if(this.videosArr.length===1){
      this.videosArr = [];
    }else{
      this.videosArr.splice(i, 1);
    }
    this.videoCount = Number(this.videoCount) - 1;
    const addMember = localStorage.getItem('addMember');
    let addData = JSON.parse(addMember);
    const videos = this.videosArr.map(res=> res.path);
    addData = { ...addData, ...{ video: JSON.stringify(videos) } };
    localStorage.setItem('addMember', JSON.stringify(addData));
    this.removeMemoryVideo=-1;
  }

          /**
   * Method to call get media list
  */
  getAllowMediaLimit(){
    const payloads={
      member_id: this.memberId,
      type : 'member'
    };
    this.memberService.getAllowMediaLimitMember(payloads).subscribe((res: any) => {
      if (res) {
        this.uploadLimit = res.data;
      }
    });
  }
 /**
   * To open the vide in new tab
   * @param doc is response of list
   */
 showDoc(doc: string) {
  window.open(doc, '_blank');
}

showVideo(video: string) {
  window.open(video, '_blank');
}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

