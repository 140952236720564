<section class="view-info-section res-pt-0">
    <div class="container">
        <div class="first-border">
            <div class="header-img-infinity">
                <img src="assets/images/viewinfo-infinity.svg" alt="viewinfo-infinity">
            </div>

            <div class="footer-img-heart">
                <img src="assets/images/viewinfo-heart.svg" alt="viewinfo-heart">
            </div>

            <div class="second-border">
                <div class="row member-title-row">
                    <div class="guestbook-message-title-sec">
                        <h2 class="guestbook-message-title">Guest Book</h2>
                        <a (click)="goBack()" class="guestbook-message-back active">Go Back</a>
                    </div>
                    <div class="guestbook-message-sec">
                        <div class="guestbook-message-left">
                            <a [ngClass]="!showGallery?'':'active'" (click)="showSection('gallery',true)"
                                class="guestbook-message">Gallery</a>
                            <a [ngClass]="!showMessage?'':'active'" (click)="showSection('message',true)"
                                class="guestbook-message">Messages</a>
                            <a *ngIf="showBroadButton" [ngClass]="!showBroadcast?'':'active'"
                                (click)="showSection('broadcast',true)" class="guestbook-message">Broadcast Messages</a>
                            <a [ngClass]="!showPrivateMessage?'':'active'" (click)="showSection('privateMessage',true)"
                                class="guestbook-message">Private
                                Messages</a>
                        </div>
                        <div class="guestbook-message-right"
                            *ngIf="showGallery && uploadLimit && (userData?.members[0]?.id === memberID)">
                            <div class="guestbook-limit">Image Limit:
                                {{uploadLimit?.Approved_images}}/{{uploadLimit?.image_upload_allowed}}</div>
                            <div class="guestbook-limit">Video Limit:
                                {{uploadLimit?.Approved_video}}/{{uploadLimit?.video_upload_allowed}}</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showGallery">
                    <div class="message-guestbook-img-wrapper">
                        <mat-list (dndDrop)="onDrop($event, mediaLists)" class="dndList flex-grow-1 d-flex row"
                            [dndDropzone]="['apple', 'banana' ]" dndEffectAllowed="move">
                            <mat-list-item class="dndPlaceholder bg-danger bg-gradient col-6 col-sm-6 col-md-3"
                                dndPlaceholderRef>
                            </mat-list-item>
                            <mat-list-item *ngFor="let picture of mediaLists; let i = index; trackBy: trackByFruit"
                                [dndDraggable]="picture" (dndStart)="onDragStart($event, i)"
                                (dndEnd)="onDragEnd( i, picture, mediaLists)"
                                (dndMoved)="onDragged(i, picture, mediaLists)" class="col-6 col-sm-6 col-md-3"
                                dndEffectAllowed="move">
                                <div class="message-guestbook-img-sec">
                                    <div class="message-guestbook-img">
                                        <div class="message-guestbook-logo">
                                            <img *ngIf="picture.type === 0" (click)="goLikeComment(picture)"
                                                [src]="picture.path" alt="picture-memory-1" class="full-img" />
                                            <img *ngIf="picture.type === 0 && picture.path === ''"
                                                src="assets/images/view-all-img-1.png" alt="Img" />
                                            <ng-container *ngIf="picture.type === 1">
                                                <video *ngIf="picture.type === 1" (click)="goLikeComment(picture)"
                                                    height="100px" width="100px">
                                                    <source [src]="picture.path + '#t=0.001'" type="video/mp4" />
                                                    <track label="English" kind="captions" srclang="en" src="" default>
                                                </video>
                                                <div class="video-icon">
                                                    <img src="./../../../assets/images/video-img.svg"
                                                        (click)="goLikeComment(picture)" alt="eternallyme video image"
                                                        class="video-icon-img" />
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="add-img-del" *ngIf="userData?.members[0]?.id == memberID">
                                            <img src="assets/images/icon-del-col.svg" alt=""
                                                (click)="removeMemory = i" />
                                        </div>
                                        <div class="reomove-modal-wrap" *ngIf="removeMemory === i">
                                            <div class="reomove-modal-sec">
                                                <div class="reomove-title">Remove Memory?</div>
                                                <button type="submit"
                                                    class="btn btn-primary-btn fill-button reomove-btn"
                                                    (click)="deleteMedia(picture)">Yes, Remove</button>
                                                <div><a class="cancel-modal-btn" (click)="removeMemory = -1">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <img src="assets/images/drag-single.svg" alt="drag drop"
                                            class="drag-drop-logo" /> -->

                                        <!-- <button type="button" class="btn btn-link delete-img-btn delete-round-img"
                                            *ngIf="showSignInGuest && memoriesArr.length > 1"><img
                                                src="./../../../assets/images/delete-round-new.svg"
                                                (click)="deleteVedio = i" alt="delete-img"></button> -->
                                        <div class="add-img-del delete-round-img"
                                            *ngIf="userData?.members[0]?.id == memberID">
                                            <img src="assets/images/delete-round-new.svg" alt="drag"
                                                (click)="removeMemory = i" />
                                        </div>

                                        <button type="button" class="btn btn-link drag-img"><img
                                                src="./../../../assets/images/drag-round-new.svg"
                                                alt="drag-img"></button>


                                    </div>
                                    <div class="message-guestbook-by">
                                        <div class="title">{{toTitleCase(picture?.guest_book?.name)}}</div>
                                        <div class="date">{{picture?.updated_at | date}}</div>
                                    </div>
                                </div>

                            </mat-list-item>
                        </mat-list>


                        <div class="no-result-section" *ngIf="!mediaLists?.length">
                            <div class="no-result-img">
                                <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                            </div>
                            <h2>No Results Found</h2>
                        </div>
                    </div>

                </ng-container>
                <!-- <ng-container *ngIf="showGallery">
                    <div class="message-guestbook-img-wrapper">
                        <div class="row">
                            <div *ngFor="let picture of mediaLists; let i = index" class="col-6 col-sm-6 col-md-3">
                                <div class="message-guestbook-img-sec">
                                    <div class="message-guestbook-img">
                                        <div class="message-guestbook-logo">
                                            <img *ngIf="picture.type === 0" (click)="goLikeComment(picture)"
                                                [src]="picture.path" alt="picture-memory-1" class="full-img" />
                                            <img *ngIf="picture.type === 0 && picture.path === ''"
                                                src="assets/images/view-all-img-1.png" alt="Img" />
                                            <ng-container *ngIf="picture.type === 1">
                                                <video *ngIf="picture.type === 1" (click)="goLikeComment(picture)"
                                                    height="100px" width="100px">
                                                    <source [src]="picture.path + '#t=0.001'" type="video/mp4" />
                                                    <track label="English" kind="captions" srclang="en" src="" default>
                                                </video>
                                                <div class="video-icon">
                                                    <img src="./../../../assets/images/video-img.svg"
                                                        (click)="goLikeComment(picture)" alt="eternallyme video image"
                                                        class="video-icon-img" />
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="add-img-del" *ngIf="userData?.members[0]?.id == memberID">
                                            <img src="assets/images/icon-del-col.svg" alt=""
                                                (click)="removeMemory = i" />
                                        </div>
                                        <div class="reomove-modal-wrap" *ngIf="removeMemory === i">
                                            <div class="reomove-modal-sec">
                                                <div class="reomove-title">Remove Memory?</div>
                                                <button type="submit"
                                                    class="btn btn-primary-btn fill-button reomove-btn"
                                                    (click)="deleteMedia(picture)">Yes, Remove</button>
                                                <div><a class="cancel-modal-btn" (click)="removeMemory = -1">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                        <img src="assets/images/drag-drop-icon.svg" alt="drag drop"
                                            class="drag-drop-logo" />
                                    </div>
                                    <div class="message-guestbook-by">
                                        <div class="title">{{toTitleCase(picture?.guest_book?.name)}}</div>
                                        <div class="date">{{picture?.updated_at | date}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="no-result-section" *ngIf="!mediaLists?.length">
                                <div class="no-result-img">
                                    <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                                </div>
                                <h2>No Results Found</h2>
                            </div>
                        </div>
                    </div>

                </ng-container> -->
                <ng-container *ngIf="showMessage">
                    <div class="message-guestbook-wrapper" *ngFor="let key of guestbookMessageList;let i=index">
                        <div class="msg-title-sec">
                            <div class="message-guestbook-left">
                                <img src="assets/images/icon-rose.svg" alt="edit" />
                            </div>
                            <div class="message-guestbook-right">
                                <div class="message-guestbook-sec">
                                    <div class="date-sec multiline-tooltip">
                                        <div class="tooltip">
                                            <div class="message-title">{{key.guest_book.name | titlecase}}</div>
                                            <div *ngIf="userData?.members[0]?.id == memberID" class="tooltiptext">
                                                <div class="tooltip-inside">
                                                    <div class="text-tooltip-title">{{key.guest_book.name | titlecase}}
                                                    </div>
                                                    <div class="text-tooltip">Email:
                                                        <span><a>{{key.guest_book.email}}</a></span>
                                                    </div>
                                                    <div class="text-tooltip">Contact Number:
                                                        <span>{{key.guest_book.contact_number}}</span>
                                                    </div>
                                                    <div class="text-tooltip">Relationship:
                                                        <span>{{key.guest_book.relation || 'NA'}}</span>
                                                    </div>
                                                    <div class="text-tooltip">Address:
                                                        <span>{{key.guest_book.address}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="message-date">{{key.updated_at | date}}</div>
                                    </div>
                                    <div class="reomove-modal-wrap remove-text-wrap" *ngIf="removeMessage===i">
                                        <div class="reomove-modal-sec">
                                            <div class="reomove-title">Remove Message?</div>
                                            <button type="submit" class="btn btn-primary-btn fill-button reomove-btn"
                                                (click)="statusUpdated(2,key.id)">Yes, Remove</button>
                                            <div><a class="cancel-modal-btn" (click)="removeMessage=-1">Cancel</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="message-title-desc">{{key.message}}</div>
                                <div *ngIf="userData?.members[0]?.id == memberID" (click)="removeMessage=i"
                                    class="message-remove">Remove Message</div>
                            </div>
                        </div>
                    </div>

                    <div class="no-result-section" *ngIf="!guestbookMessageList.length">
                        <div class="no-result-img">
                            <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                        </div>
                        <h2>No Results Found</h2>
                    </div>
                </ng-container>
                <!-- no result  -->

                <!-- start Broadcast message  -->
                <ng-container *ngIf="showBroadcast">
                    <div class="message-broadcast-wrapper" infiniteScroll [infiniteScrollDistance]="2"
                        [scrollWindow]="false" [alwaysCallback]="true" (scrolled)="nextPage$.next(undefined)">
                        <form [formGroup]="form" (ngSubmit)="submitForm()">
                            <div class="message-broadcast-top-sec">
                                <div class="message-broadcast-title">New Message</div>
                                <div class="broadcast-new-msg-sec">

                                    <div class="new-msg-to">To: <span
                                            *ngIf="firstUserName">{{firstUserName}}</span><span
                                            title="{{selectedGuestsName}}"
                                            *ngIf="prevUsers?.length>1 && firstUserName">,
                                            {{prevUsers.length-1}}+</span> <span class="select-guest-link"><a
                                                data-bs-toggle="modal" (click)="openModal()">{{(guestbookUser?.length >
                                                1) ? 'Select Guests' :
                                                'Select Guest'}}</a></span></div>

                                    <div class="new-msg-subject search-results-form">
                                        <div class="form-floating form-floating-search">
                                            <input type="text" class="form-control form-floating-margin"
                                                formControlName="subject" id="floatingInput" maxlength="100"
                                                placeholder=" "
                                                [ngClass]="form.get('subject')?.invalid && (form.get('subject')?.dirty || form.get('subject')?.touched)?'invalid-input':''" />
                                            <label htmlfor="floatingInput">Subject</label>
                                            <div class="invalid-input-text-msg" *ngIf="
                                          form.get('subject').invalid &&
                                          form.get('subject').touched
                                        ">
                                                <div *ngIf="form.get('subject').errors.required">
                                                    Subject is required
                                                </div>
                                                <div *ngIf="form.get('subject').errors.pattern">
                                                    Subject is invalid
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="new-msg-message">
                                        <div class="textarea-message textarea-padding">
                                            <div class="form-floating form-floating-add"
                                                [ngClass]="form.get('message')?.invalid && (form.get('message')?.dirty || form.get('message')?.touched)?'invalid-input':''">
                                                <textarea maxlength="500" class="form-control" id="floatingTextarea"
                                                    formControlName="message" placeholder=" "></textarea>
                                                <label for="floatingTextarea">Message</label>
                                                <div class="invalid-input-text-msg" *ngIf="
                                              form.get('message').invalid &&
                                              form.get('message').touched
                                            ">
                                                    <div *ngIf="form.get('message').errors.required">
                                                        Message is required
                                                    </div>
                                                    <div *ngIf="form.get('message').errors.pattern">
                                                        Message is invalid
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="max-characte">Max. 500 char</div>
                                    </div>
                                    <div class="new-msg-add-pic-sec">
                                        <div class="new-msg-add-pic-sec">
                                            <div class="add-pic-sec-wrap">
                                                <div class="add-img-title">Add Picture</div>
                                                <div class="add-pic-wrap">
                                                    <div class="add-pic-sec">
                                                        <div
                                                            [ngClass]="imageMail ? 'add-pic-section add-pic-none border-none':'add-pic-section add-pic-none'">
                                                            <div class="add-picture-sec border-solid" *ngIf="imageMail">
                                                                <img [src]="imageMail"
                                                                    [alt]="imageMail ===''?'eternallyme image':''"
                                                                    class="add-main-pic"
                                                                    (click)="staticModal.show();previewImage=imageMail" />
                                                                <div class="add-img-del">
                                                                    <img src="assets/images/icon-del-col.svg" alt=""
                                                                        (click)="imageMail=''" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!imageMail" class="text-center">
                                                                <img class="upload-img" src="assets/images/icon-img.svg"
                                                                    alt="add-pic" />
                                                                <div *ngIf="!imageMail" class="add-pic-title">Add
                                                                    Picture</div>
                                                                <div class="add-pic-title">JPG, PNG, Max 10 MB</div>
                                                                <input type="file" id="logoUpload"
                                                                    class="custom-input-file add-input-file-width"
                                                                    accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                                                    (change)="fileSelected($event,'image')" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="new-msg-btn-sec"><button type="submit" [disabled]="form.invalid"
                                                class="btn btn-primary-btn fill-button">Send Message</button></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="message-broadcast-bottom-sec">
                            <div class="message-broadcast-title">Previous Messages</div>
                            <ng-container *ngIf="MessageScroll?.length!=0">
                                <div *ngFor="let data of roleMapping" class="broadcast-pre-msg-sec">
                                    <div class="broadcast-to-date">
                                        <div class="msg-to">To<br />{{data.showEmail}}<span *ngIf="data?.nums>1"
                                                tooltip="{{data.email}}">, {{data?.nums-1}}+</span></div>
                                        <div class="date">{{data.date | date:'dd MMMM yyyy'}}</div>
                                    </div>
                                    <div *ngIf="data?.image" class="msg-pic-wrap">
                                        <div class="msg-pic-section">
                                            <img [src]="data?.image" [alt]="data?.image ===''?'eternallyme image':''"
                                                class="add-main-pic"
                                                (click)="staticModal.show();previewImage=data?.image" />
                                        </div>
                                    </div>
                                    <div class="broadcast-desc">{{data.message}}</div>
                                </div>
                                <ng-container *ngIf="isNextPageLoading">
                                    <app-loader></app-loader>
                                </ng-container>
                            </ng-container>
                            <div class="no-result-section" *ngIf="MessageScroll?.length==0">
                                <div class="no-result-img">
                                    <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                                </div>
                                <h2>No Results Found</h2>
                            </div>

                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="showPrivateMessage">
                    <div class="chat-message-main">
                        <div *ngIf="privateMessageListData?.length != 0" class="chat-inner-wrap">
                            <div *ngFor="let data of privateMessageListData">
                                <div class="chat-message-inner-wrap"
                                    [ngClass]="{'': data?.read_by_owner !== 0, 'dotted': data?.read_by_owner === 0}">
                                    <div *ngIf="data?.read_by_owner == 0" class="chat-message-dot"></div>
                                    <div class="user-profile-wrap">
                                        <span class="user-profile-img"><img
                                                [src]="data?.profile_picture || 'assets/images/user-img.svg'"
                                                alt="viewinfo-heart">
                                        </span>
                                        <div class="user-profiel-info">
                                            <h4 class="user-profile-title">{{data?.name}}</h4>
                                        </div>
                                        <span class="chat-message-date">{{data?.created_at | date:'dd MMM yyyy'}}</span>
                                    </div>
                                    <p class="chat-message-des">{{data?.message}}</p>
                                    <div *ngIf="data?.media?.length != 0" class="image-video-wrap">
                                        <div *ngFor="let mediaFiles of data?.media" class="chat-msg-img-vedio">
                                            <img *ngIf="mediaFiles?.type ==1" [src]="mediaFiles?.url" alt="photo"
                                                class="chat-msg-upload-img"
                                                (click)="staticModal.show();previewImage=mediaFiles?.url" />
                                            <video [src]="mediaFiles?.url" height="100px" width="100px"
                                                *ngIf="mediaFiles?.url!=='' && mediaFiles?.type == 2"
                                                class="upload-video view-all-memory-img">
                                                <track label="English" kind="captions" srclang="en" src="" default>
                                            </video>
                                        </div>
                                    </div>
                                    <div (click)="goToChatWithGuestUser(data?.guestbook_id)" class="reply-btn-wrap">
                                        <span class="reply-text">Reply
                                            <img src="assets/images/reply-logo.svg" alt="reply logo" class="reply-logo">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="privateMessageListData?.length == 0" class="chat-empty-wrap">
                            <img src="assets/images/chat-empty-logo.svg" alt="chat empty" class="chat-empty-img">
                            <p class="chat-empty-des">No message found.</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="pagination-section" *ngIf="showGallery && mediaLists && mediaLists?.length">
            <nav aria-label="Page navigation example ms-auto">
                <ul class="pagination" *ngIf="searchData?.total>10">
                    <li class="page-item" *ngFor="let page of paginationArr;let isLast=last;let isFirst = first;">
                        <button [disabled]="currentPage === 1" class="page-link"
                            [ngClass]="currentPage === 1 ? 'activeDisabled':''" *ngIf="page==='pre'"
                            (click)="pageChanged(page)">
                            <span aria-hidden="true"><img
                                    [src]="currentPage !== 1 ? 'assets/images/pagination-arrow-left.svg' : 'assets/images/pagination-arrow-left-hide.svg'"
                                    alt="pagination-arrow"></span>
                        </button>
                        <a class="page-link" *ngIf="page!=='pre' && page!=='next'"
                            [ngClass]="page===currentPage?'pg-text-li':''" (click)="pageChanged(page)">
                            {{page}}
                            <ng-container *ngIf="page===currentPage">
                                of <span class="color-text-pg">{{searchData?.pageCount}}
                                    Pages</span>
                            </ng-container>
                        </a>
                        <button [disabled]="searchData?.pageCount === currentPage"
                            [ngClass]="searchData?.pageCount === currentPage ? 'activeDisabled':''"
                            *ngIf="page==='next'" class="page-link" (click)="pageChanged(page)">
                            <span aria-hidden="true"><img
                                    [src]="searchData?.pageCount !== currentPage  ? 'assets/images/pagination-arrow-right.svg' : 'assets/images/pagination-arrow-right-hide.svg'"
                                    alt="pagination-arrow"></span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>


        <div class="pagination-section" *ngIf="showMessage && guestbookMessageList && guestbookMessageList?.length">
            <nav aria-label="Page navigation example ms-auto">
                <ul class="pagination" *ngIf="searchMessageData?.total>10">
                    <li class="page-item"
                        *ngFor="let page of paginationMessageArr;let isLast=last;let isFirst = first;">
                        <button [disabled]="currentMessagePage === 1" class="page-link"
                            [ngClass]="currentMessagePage === 1 ? 'activeDisabled':''" *ngIf="page==='pre'"
                            (click)="pageChangedMessage(page)">
                            <span aria-hidden="true"><img
                                    [src]="currentMessagePage !== 1 ? 'assets/images/pagination-arrow-left.svg' : 'assets/images/pagination-arrow-left-hide.svg'"
                                    alt="pagination-arrow"></span>
                        </button>
                        <a class="page-link" *ngIf="page!=='pre' && page!=='next'"
                            [ngClass]="page===currentMessagePage?'pg-text-li':''" (click)="pageChangedMessage(page)">
                            {{page}}
                            <ng-container *ngIf="page===currentMessagePage">
                                of <span class="color-text-pg">{{searchMessageData?.pageCounts}}
                                    Pages</span>
                            </ng-container>
                        </a>
                        <button [disabled]="searchMessageData?.pageCounts === currentMessagePage"
                            [ngClass]="searchMessageData?.pageCounts === currentMessagePage ? 'activeDisabled':''"
                            *ngIf="page==='next'" class="page-link" (click)="pageChangedMessage(page)">
                            <span aria-hidden="true"><img
                                    [src]="searchMessageData?.pageCounts !== currentMessagePage  ? 'assets/images/pagination-arrow-right.svg' : 'assets/images/pagination-arrow-right-hide.svg'"
                                    alt="pagination-arrow"></span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="pagination-section" *ngIf="showBroadcast && MessageScroll && MessageScroll?.length">
            <nav aria-label="Page navigation example ms-auto">
                <ul class="pagination" *ngIf="totalAllCount?.total>10">
                    <li class="page-item" *ngFor="let page of paginationBoardArr;let isLast=last;let isFirst = first;">
                        <button [disabled]="currentBoardCastPage === 1" class="page-link"
                            [ngClass]="currentBoardCastPage === 1 ? 'activeDisabled':''" *ngIf="page==='pre'"
                            (click)="pageChange(page)">
                            <span aria-hidden="true"><img
                                    [src]="currentBoardCastPage !== 1 ? 'assets/images/pagination-arrow-left.svg' : 'assets/images/pagination-arrow-left-hide.svg'"
                                    alt="pagination-arrow"></span>
                        </button>
                        <a class="page-link" *ngIf="page!=='pre' && page!=='next'"
                            [ngClass]="page===currentBoardCastPage?'pg-text-li':''" (click)="pageChange(page)">
                            {{page}}
                            <ng-container *ngIf="page===currentBoardCastPage">
                                of <span class="color-text-pg">{{totalAllCount?.Count}}
                                    Pages</span>
                            </ng-container>
                        </a>
                        <button [disabled]="totalAllCount?.Count === currentBoardCastPage"
                            [ngClass]="totalAllCount?.Count === currentBoardCastPage ? 'activeDisabled':''"
                            *ngIf="page==='next'" class="page-link" (click)="pageChange(page)">
                            <span aria-hidden="true"><img
                                    [src]="totalAllCount?.Count !== currentBoardCastPage  ? 'assets/images/pagination-arrow-right.svg' : 'assets/images/pagination-arrow-right-hide.svg'"
                                    alt="pagination-arrow"></span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
            tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
            <div class="modal-dialog modal-sm image-preview-modal-dialog">
                <div class="modal-content image-preview-modal-content">
                    <div class="modal-body">
                        <div class="image-preview-wrapper">
                            <img [src]="previewImage" alt="image-preview" class="image-preview img-fluid" />
                        </div>
                        <button type="button" class="btn btn-link image-preview-close-btn"
                            (click)="staticModal.hide()"><img src="assets/images/image-preview-close.svg"
                                alt="close-btn" class="img-fluid" /></button>
                    </div>
                </div>
            </div>
        </div>
