import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IResponse } from '../../interfaces/response';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  public editProfile: FormGroup;
  public isFullNameEdit = false;
  public isEmailEdit = false;
  public destroy$ = new Subject();
  public userData;
  params: string;
  public profilePic: string;
  public userId: any;
  constructor(public formBuilder: FormBuilder, public router: Router, public modalRef: BsModalRef, public commonService: CommonService,
    public authService: AuthService, public toastr: ToastrService, public utilityService: UtilityService) {
    this.editProfile = this.formBuilder.group({
      emailId: [{ value: '', disabled: true }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),]],
      fullName: [{ value: '', disabled: true }, Validators.required]
    });
  }

  ngOnInit(): void {
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
        this.profilePic = this.userData.user.profile_picture;
        this.userId = this.userData.user.id;
        this.editProfile.patchValue({
          fullName: this.userData.user.name,
          emailId: this.userData.user.email
        });
      }
    });
  }
  get fullName() { return this.editProfile.get('fullName'); }
  get emailId() { return this.editProfile.get('emailId'); }

  /**
  * To enable or disable the fields based on field name
  * @param fieldName and @param value is required
 */
  enableFields(fieldName: string, value: boolean) {
    this.editProfile.controls[fieldName][value ? 'enable' : 'disable']();
    this.editProfile.patchValue({
      fullName: this.userData.user.name,
      emailId: this.userData.user.email
    });
  }
  /**
   * API Call for updating the user profile
  */

  uploadProfilePic(event, type?) {
    const fd = new FormData();
    const checkType = type ? event[0].type.split('/')[0] : event.target.files[0].type.split('/')[0];
    const checkSize = type ? event[0] : event.target.files[0];
    fd.append('image', type ? event[0] : event.target.files[0]);
    if (checkType !== 'image') {
      this.toastr.error('Please upload image');
    } else if (checkSize <= MagicNumbers.MbSize) {
      this.toastr.error(`Please upload less than 10 mb image`);
    } else {
      this.utilityService.uploadImage(fd, 'profile_image').subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.profilePic = res.data.url;
          this.utilityService.updateProfileImage(this.userId,this.profilePic).subscribe((res1:IResponse)=>{
            if (res1 && !res1.errors) {
              const userData = localStorage.getItem('userData');
          const updatePicture = JSON.parse(userData);
          updatePicture.user.profile_picture = this.profilePic;
          this.commonService.userData.next(updatePicture);
          this.commonService.mediaApiCall.next(true);
          localStorage.setItem('userData', JSON.stringify(updatePicture));
            this.toastr.success('Profile picture uploaded successfully.');
            }
          })
        };
      });
    }
  }

  updateProfile(isChanged: string) {
    if (this.editProfile.valid) {
      this.setParams(isChanged);
      this.authService.updateProfile(this.params).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.userData.user.name = res.data.name;
          this.userData.user.email = res.data.email;
          localStorage.setItem('userData', JSON.stringify(this.userData));
          if(isChanged === 'email'){
            this.logout();
          } 
          this.modalRef.hide();
        }
      });
    } else {
      this.editProfileForm();
    }
  }
   /**
   * add value for edit profile
  */
  editProfileForm(){
    if (this.isFullNameEdit && this.isEmailEdit) {
      this.editProfile.markAllAsTouched();
    } else if (this.isFullNameEdit) {
      this.editProfile.controls.fullName.markAsTouched();
    } else if (this.isEmailEdit) {
      this.editProfile.controls.emailId.markAsTouched();
    }
  }
  /**
   * set params for api call
  */
  setParams(isChanged){
    if (isChanged === 'fullName') {
      this.params = `id=${this.userData.user.id}&full_name=${this.editProfile.getRawValue().fullName}`;
    } else {
      this.params = `id=${this.userData.user.id}&email=${this.editProfile.getRawValue().emailId}`;
    }
  }
 /**
   * Logout api call
  */
  logout(){
    this.authService.logout().subscribe((res1: IResponse) => {
      if (res1 && !res1.errors) {
        this.commonService.userData.next(null);
        localStorage.clear();
        this.router.navigate(['/landing-page']);
      }
    });
  }
}
