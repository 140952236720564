import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { CommonService } from '../../common/services/common.service';
import { MemberService } from '../../common/services/member.service';
import { IResponse } from '../../common/interfaces/response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-family-memories',
  templateUrl: './family-memories.component.html',
  styleUrls: ['./family-memories.component.scss']
})
export class FamilyMemoriesComponent implements OnInit {
  public familyMemoriesForm: FormGroup;
  public isSubmitted = false;
  public showMaxlength1 = false;
  public showMaxlength2 = false;
  public update = false;
  public tinyMceKey;
  memberId: string;
  action:string;
  updateHighlight: any;

  constructor(public router: Router, public formBuilder: FormBuilder, public commonService: CommonService,
    public toastr: ToastrService, public loader: NgxSpinnerService, public memberService: MemberService, public activatedRoute: ActivatedRoute) {
    this.tinyMceKey = environment.tinyMceKey;
    this.familyMemoriesForm = this.formBuilder.group({
      aboutFamily: ['', [Validators.required]],
      favoriteMemories: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (localStorage.getItem('member_id')) {
      this.memberId = localStorage.getItem('member_id');
      this.update = true;
      this.getMemberData(this.memberId);
      this.getMemberDataHighlight(this.memberId);      
    }

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.update = true;
        this.getMemberData(this.memberId); 
        this.getMemberDataHighlight(this.memberId);      
      }
    });

    if (localStorage.getItem('update')) {
      this.update = true;
    }
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
    }, MagicNumbers.thousand);
  }
  get aboutFamily() { return this.familyMemoriesForm.get('aboutFamily'); }
  get favoriteMemories() { return this.familyMemoriesForm.get('favoriteMemories'); }


  getMemberData(id) {
    this.memberService.getMemberStep(id, MagicNumbers.four).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const aboutFamilyMemories = res.data;
        this.familyMemoriesForm.patchValue({
          aboutFamily: aboutFamilyMemories?.about_family == 'undefined' ? '': aboutFamilyMemories?.about_family,
          favoriteMemories: aboutFamilyMemories?.favorite_memories == 'undefined' ? '': aboutFamilyMemories?.favorite_memories
        });      
      }
    });
  }

  getMemberDataHighlight(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const result = res.data;
        if(result.member_gallery.length!=0){
          this.updateHighlight = true;
        } else{
          this.updateHighlight = false;
        }
      }
    });
  }
  /**
   * To navigate to previous step
  */
  goBack() {
    this.router.navigate(['/about-careers-hobbies', this.memberId]);
  }
  /**
   * To navigate to next and saving the data
  */
  onSubmit(action:string) {
    const countWord1 = document.getElementById('memorial1')?.innerText;
    const countWord2 = document.getElementById('memorial2')?.innerText;
    this.isSubmitted = true;
    if (this.familyMemoriesForm.valid) {
      const countWordLen1 = countWord1.trim().split(/\s+/).length;
      const countWordLen2 = countWord2.trim().split(/\s+/).length;
      if (countWordLen1 > MagicNumbers.threeHundred) {
        this.showMaxlength1 = true;
      }
      if (countWordLen2 > MagicNumbers.threeHundred) {
        this.showMaxlength2 = true;
      } else if (countWordLen1 <= MagicNumbers.threeHundred && countWordLen2 <= MagicNumbers.threeHundred) {

        // if(this.update){
          this.memberService.updateMember4(this.memberId, MagicNumbers.four, { about_family: this.familyMemoriesForm.value.aboutFamily, favorite_memories: this.familyMemoriesForm.value.favoriteMemories }).subscribe((res: IResponse) => {
            if (res && !res.errors) {
              if(action == 'saveNext'){
                this.router.navigate(['/add-pictures-videos', this.memberId]);
              } else{
                this.toastr.success('Details updated successfully.');
                this.router.navigate(['/view-information/',res.data.id]);
              }
            }
          });
        this.commonService.familyMemories.next({
          about_family: this.familyMemoriesForm.value.aboutFamily,
          favorite_memories: this.familyMemoriesForm.value.favoriteMemories
        });
        const addMember = localStorage.getItem('addMember');
        let addData = JSON.parse(addMember);
        addData = { ...addData, ...{ about_family: this.familyMemoriesForm.value.aboutFamily, favorite_memories: this.familyMemoriesForm.value.favoriteMemories } };
        localStorage.setItem('addMember', JSON.stringify(addData));
        this.router.navigate(['/add-pictures-videos', this.memberId]);
      }
    } else {
      this.familyMemoriesForm.markAllAsTouched();
    }
  }
  /**
   * To listen the editor 1 changes event
  */
  editorChanges1(e) {
    this.showMaxlength1 = false;
  }
  /**
   * To listen the editor 2 changes event
  */
  editorChanges2(e) {
    this.showMaxlength2 = false;
  }
}
