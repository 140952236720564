import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse } from '../../common/interfaces/response';
import { MemberService } from '../../common/services/member.service';
import { AddGuestbookService } from '../../common/services/add-guestbook.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../common/services/utility.service';
import { CommonService } from '../../common/services/common.service';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SelectGuestbookModalComponent } from './select-guestbook-modal/select-guestbook-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'app-guestbook-gallery',
  templateUrl: './guestbook-gallery.component.html',
  styleUrls: ['./guestbook-gallery.component.scss']
})
export class GuestbookGalleryComponent implements OnInit , AfterViewInit {
  public mediaLists:any=[];
  public memberID: any;
  public guestbookMessageList: any = [];
  public showMessage: boolean;
  public showGallery: boolean;
  public showBroadcast: boolean;
  public showPrivateMessage: boolean;
  public previewImage: string;
  public MessageScroll: any=[];
  public page: any=1;
  public totalAllCount;
  public uploadLimit: any;
  public paginationArr = [];
  public paginationMessageArr=[];
  public paginationBoardArr=[];
  public currentPage = 1;
  public pageData =1;
  public currentMessagePage=1;
  public currentBoardCastPage=1;
  public searchData;
  public searchMessageData;
  public userData: any;
  public removeMemory :any;
  public modalRef: BsModalRef;
  public removeMessage :any;
  public isNextPageLoading = false;
  public nextPage$ = new BehaviorSubject<void>(undefined);
  public showDivider = false;
  public items$: any;
  public form: FormGroup;
  public picture:any;
  public imageMail:any;
  public showBroadButton:any;
  public prevUserData: any;
  public prevUsers: any = [];
  public firstUserName: any;
  public goToLocation: any;
  public selectedGuestsName: string;
  public guestbookUser: any;
  showSignInGuest: any;
  showSignInMember: any;
  privateMessageListData: any = [];
  remove;

  constructor(
    public memberService: MemberService, 
    public router : Router,
    public modalService: BsModalService,
    public loaderService: NgxSpinnerService,
    public addGuestbookService: AddGuestbookService,
    public toastr: ToastrService,
    public utilityService: UtilityService,
    public commonService: CommonService,
    private readonly formBuilder: FormBuilder,
    public location: Location,
    public activateRoute: ActivatedRoute
    ) { 
      this.showGallery = true;
      this.previewImage = '';
      this.paginationArr = ['pre'];
      this.paginationMessageArr = ['pre'];

      this.form = this.formBuilder.group({
        to_users: [''],
        subject: ['',[Validators.required,Validators.maxLength(MagicNumbers.oneHundred),Validators.pattern(/^(?!\s+$)[a-zA-Z0-9 ]+$/)]],
        message: ['',[Validators.required,Validators.pattern(/^(?!\s+$)[a-zA-Z0-9 ,.\r\n\t\f\v\-\+\*\(\)\[\]\{\}\^\$\\\|\?\/!@#$%&*_=`~:;<>']+$/)]],
        image: [this.imageMail],
        all_guest: ['0']
      });
      this.activateRoute.queryParams.subscribe(queryParams => {
        if (queryParams?.showSignInGuest && queryParams?.showSignInMember) {
          this.showSignInGuest = JSON.parse(queryParams?.showSignInGuest);
          this.showSignInMember = JSON.parse(queryParams.showSignInMember);
        }
       const userData = JSON.parse(localStorage.getItem('userData'));
       if(userData){
         this.checkPendingReq(userData?.user.member?.id);
       }
      });

    }

  ngOnInit(): void {
    this.showGallery = true;
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberID = params.id;
      } else{
        this.memberID = JSON.parse(localStorage.getItem('member_id'));
      }
    });
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if(this.userData){
      this.checkPendingReq(this.userData?.members[0]?.id);
    }
    this.getAllowMediaLimit();
    this.getMessageList(this.currentMessagePage);
    this.getMediaLists('',this.currentPage);
    this.messageScroll(this.currentBoardCastPage);
    this.page = 1;
    
this.goToLocation = JSON.parse(localStorage.getItem('gallerySection'));
this.showSection(this.goToLocation.active,true);
    
this.getPrevList();
const local = localStorage.getItem('selectedUser');

if (local) {
  this.prevUserData = JSON.parse(local);
  this.prevUsers = this.prevUserData?.values;
  this.firstUserName = this.prevUserData?.values[0]?.name;
}

const login =  localStorage.getItem('userData');

if(login){
this.showBroadButton = true;
} else{
  this.showBroadButton = false;
}
this.getGuestUserData();
this.activateRoute.queryParams.subscribe(params => {
  this.showPrivateMessage = params['showPrivateMessage'] === 'true';
  if(this.showPrivateMessage == true){
    this.showGallery = false;
    this.showPrivateMessage = true;
  }
});
this.privateMessageList();
  }

  submitForm() {
    this.loaderService.show();
    const payloads = {};
    const local = localStorage.getItem('selectedUser');
    if (local) {
      this.prevUserData = JSON.parse(local);
      this.prevUsers = this.prevUserData.values;

      if(!this.prevUserData.selectedAll){
        payloads['all_guest'] = '0';
        payloads['to_users'] = this.prevUsers;
      } else{
        payloads['all_guest'] = '1';
        payloads['to_users'] = [];
      }
    }
    if(!this.prevUsers?.length){
      this.toastr.error(`Please Select Guest`);
      this.loaderService.hide();
      return;
    }

    payloads['member_id'] = this.memberID;
    payloads['subject'] = this.form.value.subject;
    payloads['message'] = this.form.value.message;

    if (this.imageMail) {
      payloads['image'] = this.imageMail;
    }
    
    this.utilityService
      .submitForm(payloads)
      .subscribe((res: any) => {
        if (res) {
          this.form.reset();
          this.imageMail = null;
          this.messageScroll(this.currentBoardCastPage);
          this.firstUserName = '';
          localStorage.removeItem('selectedUser');
          this.loaderService.hide();
          this.toastr.success(res.message);
        }
      },(error)=>{
        this.loaderService.hide();
      });
  }

  goBack(){
      this.router.navigate(['/view-information/',this.memberID]);
  }

  getGuestUserData() {
      const payload = `member_id=${this.memberID}`;
    this.addGuestbookService.getGuestUser(payload).subscribe((res: any) => {
      if (res) {
        this.guestbookUser = res.data.data || [];
      }
    });
  }

  fileSelected(event, type?) {
    const fd = new FormData();
    fd.append(event.target.files[0].type.split('/')[0], event.target.files[0]);
    const uploadType = event.target.files[0].type.split('/')[0];
      if (uploadType !== type) {
        this.toastr.error(`Please upload ${type}`);
      } else if (event.target.files[0].size >= MagicNumbers.MbImageSize) {
        this.toastr.error(`Please upload less than 10 mb ${uploadType}`);
      } else {
        this.utilityService['uploadImage'](fd,'other').subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.imageMail = res.data.url;
            this.toastr.success(res.message);
          }
          event.target.value = '';
        });
      }
  }



  roleMapping = [];


  getPrevList() {
    this.roleMapping = [];
    let d = [];
    d = this.MessageScroll.filter(item => item.to_users);

for (const value of d) {
        let roleMail = '';
        let roleEmail = '';
        let roleName = '';
        let roleId = '';
        const roleMessage = value.message;
        const roleDate = value.created_at;
        const roleImage = value.image;
        let roleNums;
        if(value.to_users[0]){
          value.to_users[0]['title'] = 'index0';
        }

        for(const val of value.to_users){
          roleId = val.id;
          if(val.title){
            roleMail = '';
          } else{
            roleMail = roleMail + val.name + ', ';
          }
          roleName = val.name;
          roleNums = value.to_users.length;
        }
        roleEmail = value.to_users[0]?.name;

        this.roleMapping.push({'id': roleId, 'role_name':roleName,'showEmail':roleEmail, 'image': roleImage, 'nums':roleNums, 'message':roleMessage,'date':roleDate ,'email':roleMail.substring(0,roleMail.length - MagicNumbers.two)});
      
      }


  // });
  }
  ngAfterViewInit(): void {
    localStorage.getItem('selectedUser');
  }

    checkPendingReq(memberID){
      this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
        if (res && !res.errors) {
           this.commonService.guestRequestSharing.next(res.data);
        }
        });
    }
    
    getMessageList(Page) {
      this.paginationMessageArr = ['pre'];
      this.currentMessagePage = Page ? Page : 1;
      const payloads = {
        member_id: this.memberID,
        page : Page,
        source :'detail_page'
      };
      this.memberService
        .getMessageList(payloads)
        .subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.removeMessage=-1;
            this.guestbookMessageList = res.data.data || [];
            this.searchMessageData = res.data;
            this.searchMessageData.pageCounts = Math.ceil((this.searchMessageData?.total) / MagicNumbers.ten);
            for (let index = 1; index <= this.searchMessageData.pageCounts; index++) {
              this.paginationMessageArr.push(index);
            }
            this.paginationMessageArr.push('next');
          }
        });
    }
messageScroll(Page) {
  this.paginationBoardArr = ['pre'];
      this.currentBoardCastPage = Page ? Page : 1;
const payloads = {
member_id: this.memberID,
page:Page
};
this.memberService
.getBroadCastMessageList(payloads)
.subscribe((res: any) => {
if (res && !res.errors && res.data.data) {
  if(res.data.data.length){
    this.MessageScroll =res.data.data || [];
    this.totalAllCount = res.data;
    this.totalAllCount.Count = Math.ceil((this.totalAllCount?.total) / MagicNumbers.ten);
        for (let index = 1; index <= this.totalAllCount.Count; index++) {
          this.paginationBoardArr.push(index);
        }
        this.paginationBoardArr.push('next');
      // }
  }
    this.getPrevList();
  } else{
    this.getPrevList();
  }
});
}

        /**
     * To open the vide in new tab
     * @param video is response of list
     */
    showVideo(video: string) {
      window.open(video, '_blank');
    }
    
    showSection(section,value){
      if(section === 'message'){
      this.showMessage = value;
      this.showGallery = false;
      this.showBroadcast = false;
      this.showPrivateMessage = false;
      } else if(section === 'broadcast'){
      this.page = 1;
      this.showBroadcast = value;
      localStorage.removeItem('selectedUser');
      this.firstUserName = '';
      this.prevUsers = null;
      this.showGallery = false;
      this.showMessage = false;
      this.showPrivateMessage = false;
      } else if(section === 'privateMessage'){
        this.showGallery = false;
        this.showMessage = false;
        this.showBroadcast = false;
        this.showPrivateMessage = true;
        } else{
      this.showGallery = value;
      this.showMessage = false;
      this.showBroadcast = false;
      this.showPrivateMessage = false;
      }
      }
      

  /**
   * API Call to get media list
   * type blank string means all picture and video
   */
  getMediaLists(Type:any,Page) {
    this.paginationArr = ['pre'];
    this.currentPage = Page ? Page : 1;
    this.mediaLists = [];
    const payloads = {
      member_id: this.memberID,
      type : Type,
      page : Page,
      source:'detail_page'
    };
    this.memberService.getMediaList(payloads).subscribe((res: any) => {
      if (res && !res.errors) {
        this.removeMemory=-1;
        this.mediaLists = res.data.data;
        this.searchData = res.data;
        this.searchData.pageCount = Math.ceil((this.searchData?.total) / MagicNumbers.twelve);
        for (let index = 1; index <= this.searchData.pageCount; index++) {
          this.paginationArr.push(index);
        }
        this.paginationArr.push('next');
      }
    });
  }
  /**
* API Call to update the status
*/
statusUpdated(value,Id){
  const payloads = {
    id: Id,
    status : value
  };
  this.addGuestbookService.updateGuestbookRequest(payloads).subscribe((res: any) => {
    if (res) {
      this.toastr.success(res.message);
      this.guestbookMessageList = [];
      this.getMessageList(this.currentMessagePage);
      this.getAllowMediaLimit();
    }
  });
}

       /**
* API Call to delete the status
*/
   deleteMedia(value){
    const payloads = {
      id: value.id,
      guestbook_id:value.guestbook_id
    };
    this.addGuestbookService.deleteMediaPicture(payloads).subscribe((res: any) => {
      if (res) {
        this.toastr.success(res.message);
        this.mediaLists = [];
        this.getMediaLists('',this.currentPage);
        this.getAllowMediaLimit();
      }
    });
  }

    /**
   * Method to call get media list
  */
  getAllowMediaLimit(){
    const payloads={
      member_id: this.memberID,
    };
    this.addGuestbookService.getAllowMediaLimit(payloads,'guest_approve').subscribe((res: any) => {
      if (res) {
        this.uploadLimit = res.data;
      }
    });
  }

  privateMessageList(){
    this.memberService.getMessageListForOwner(this.memberID).subscribe((res: any) => {
      if (res) {
        this.privateMessageListData = res.data;
      }
    });
  }

  goToChatWithGuestUser(guestId){
    this.router.navigate(['/send-message-user/', guestId]);
  }
    /**
     * On page changed
     * @param page is required
    */
    pageChanged(page) {
      window.scrollTo(0, 0);
      if (page === 'next' && this.searchData?.pageCount > this.currentPage) {
        this.currentPage = this.currentPage + 1;
        this.getMediaLists('',this.currentPage);
      } else if (page === 'pre' && this.searchData?.pageCount >= this.currentPage) {
        this.currentPage = Number(this.currentPage) - 1;
        this.getMediaLists('',this.currentPage);
      } else if (page !== 'pre' && page !== 'next') {
        this.currentPage = page;
        this.getMediaLists('',this.currentPage);
      }
    }
        /**
     * On page change boardcast message
     * @param page is required
    */
        pageChange(page) {
          window.scrollTo(0, 0);
          if (page === 'next' && this.totalAllCount?.Count > this.currentBoardCastPage) {
            this.currentBoardCastPage = this.currentBoardCastPage + 1;
            this.messageScroll(this.currentBoardCastPage);
          } else if (page === 'pre' && this.totalAllCount?.Count >= this.currentBoardCastPage) {
            this.currentBoardCastPage = Number(this.currentBoardCastPage) - 1;
            this.messageScroll(this.currentBoardCastPage);
          } else if (page !== 'pre' && page !== 'next') {
            this.currentBoardCastPage = page;
            this.messageScroll(this.currentBoardCastPage);
          }
        }

    /**
     * On page changed Message
     * @param page is required
    */
    pageChangedMessage(page) {
      window.scrollTo(0, 0);
      if (page === 'next' && this.searchMessageData?.pageCounts > this.currentMessagePage) {
        this.currentMessagePage = this.currentMessagePage + 1;
        this.getMessageList(this.currentMessagePage);
      } else if (page === 'pre' && this.searchMessageData?.pageCounts >= this.currentMessagePage) {
        this.currentMessagePage = Number(this.currentMessagePage) - 1;
        this.getMessageList(this.currentMessagePage);
      } else if (page !== 'pre' && page !== 'next') {
        this.currentMessagePage = page;
        this.getMessageList(this.currentMessagePage);
      }
    }
    /**
     * Name Message Uppercase
    */
     toTitleCase(str) {
      return str.toLowerCase().split(' ').map(function(word) {
          return word.charAt(0).toLocaleUpperCase() + word.slice(1);
      }).join(' ');
  }

    /**
   * Opening the sign in for guestbook
   */
    openModal() {
      this.modalRef = this.modalService.show(SelectGuestbookModalComponent,
        { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: 'modal-dialog modal-dialog-centered custom-modal social-modal' });
      this.modalRef.content.onClose.subscribe((result) => {
        if (result.values) {
          this.prevUsers = result?.values;
          this.firstUserName = result?.values[0]?.name;
          const placeholderArray = this.prevUsers.slice(1);
          this.selectedGuestsName = placeholderArray.map(obj=>obj.name).join(', ');
        }
      });
    }
    
    /**
    * API Call to go like screen
    */
    goLikeComment(picture:any) {
      localStorage.removeItem('showCommentInput');
        this.router.navigate(['/like-comment'],  
        {
          queryParams: {
            id: this.memberID,
            pictureId : picture.id,
            showSignInGuest :  this.showSignInGuest ? this.showSignInGuest : true,
            showSignInMember : this.showSignInMember ? this.showSignInMember : false
          },
        });
    }
  
    updateOrder(mediaLists) {
      let orderData = mediaLists.map((item, index) => ({
        id: item.id,
        order: index
      }));
    
      // Use a Set to track seen IDs and filter out duplicates (keep the first occurrence)
      const seenIds = new Set();
      orderData = orderData.filter(item => {
        if (seenIds.has(item.id)) {
          return false; // If the ID is already seen, remove the item
        }
        seenIds.add(item.id);
        return true; // Keep the item if the ID hasn't been seen yet
      });

      this.addGuestbookService.dragAndDropGuest({ order_data: orderData }).subscribe(response => {
        if (response) {
        } else {
        }
      });  
      // Send orderData to your backend or perform any further actions
    }


  trackByFruit(index: number, fruit) {
    return fruit;
  }


  onDragStart(event,index:number) {
    this.remove  = index;
  }

    
  onDragEnd(index:number,fruit, mediaLists) {
  }

  onDragged(index: number, fruit, mediaLists) {
  }

  onDrop(event: DndDropEvent, mediaLists: any[]) {   
      const draggedItem = event.data;
      mediaLists.splice(this.remove, 1);
    
    let newIndex = event.index;
    if (typeof newIndex === 'undefined') {
      newIndex = mediaLists.length;
    }

    if(event.index > this.remove){
      mediaLists.splice(newIndex, 0, draggedItem);  
} else{
  mediaLists.splice(newIndex, 0, draggedItem);  
}
    this.updateOrder(mediaLists);
  }
}
