
import { ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../../app/common/interfaces/response';
import { MemberService } from '../../../common/services/member.service';
import { ISearchObj } from '../../../common/interfaces/states';
import { EditInvitationComponent } from '../../../common/modal/edit-invitation/edit-invitation.component';
import { SearchService } from '../../../common/services/search.service';
import { ConfirmItineraryComponent } from '../../../common/modal/confirm-itinerary/confirm-itinerary.component';
import { MagicNumbers } from 'src/app/common/component/app-magic-numbers';
declare const google: any;

@Component({
  selector: 'app-create-itinerary',
  templateUrl: './create-itinerary.component.html',
  styleUrls: ['./create-itinerary.component.scss']
})
export class CreateItineraryComponent implements OnInit{

  memberList: any;
  public createIteneraryForm: FormGroup;
  ceremonyTimeControl: FormControl;
  gatheringTimeControl: FormControl;
  @ViewChild('searchLocation', { static: false }) searchElementRef: ElementRef;
  @ViewChild('gatheringLocation', { static: false }) addressElementRef: ElementRef;
  memberId: any;
  memberName: any;
  gatheringInvitationText: string;
  invitationText: string;
  isTimeFocused;
  timeFocused;
  gatheringTime: Date;
  ceremonyTime: Date;


  constructor(public modalService: BsModalService,
    public formBuilder: FormBuilder, public loaderService: NgxSpinnerService,
    public location: Location, public router: Router,
    public inj: Injector, public memberService: MemberService, public activatedRoute: ActivatedRoute, 
    public searchService: SearchService, private readonly cdr: ChangeDetectorRef) {

    this.loader = inj.get<NgxSpinnerService>(NgxSpinnerService);
    this.datePipe = inj.get<DatePipe>(DatePipe);
    this.toastr = inj.get<ToastrService>(ToastrService);

    this.createIteneraryForm = this.formBuilder.group({
      nameOfPlace: ['', Validators.required],
      searchLocation: ['', Validators.required],
      searchLatitude: [''],
      searchLongitude: [''],
      ceremonyDate: ['', Validators.required],
      ceremonyTime: ['', Validators.required],
      invitationText: ['', Validators.required],
      gatheringPlace: [''],
      gatheringLocation: [''],
      gatheringLatitude: [''],
      gatheringLongitude: [''],
      gatheringDate: [''],
      gatheringTime: [''],
      gatheringInvitationText: ['']
    });
    this.ceremonyTimeControl = this.createIteneraryForm.get('ceremonyTime') as FormControl;
    this.gatheringTimeControl = this.createIteneraryForm.get('gatheringTime') as FormControl;
  }

  public searchObj: ISearchObj = {};
  public minDate = new Date();
  public maxDate = new Date();
  public today = new Date();
  public loader: NgxSpinnerService;
  public datePipe: DatePipe;
  public toastr: ToastrService;



  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.getMember();
      }
    });

  }

  onTimeChangeGathering(event: any): void {
    this.createIteneraryForm.get('gatheringTime').setValue(event);
    this.updateInvitationText();
  }

  onTimeChangeCeremony(event: any): void {
    this.createIteneraryForm.get('ceremonyTime').setValue(event);
    this.updateGuestInvitationText();
  }

  updateInvitationText() {
    const gatheringPlace = this.createIteneraryForm.get('gatheringPlace').value || '';
    const gatheringLocation = this.createIteneraryForm.get('gatheringLocation').value || 'XYZ location';
    const gatheringDateValue = this.createIteneraryForm.get('gatheringDate').value;
    const gatheringDate  = gatheringDateValue
    ? this.datePipe.transform(new Date(gatheringDateValue), 'MM/dd/yyyy')
    : 'date';
    const gatheringTime = this.createIteneraryForm.get('gatheringTime').value || 'time';

    this.gatheringInvitationText = `You are invited to gather with the family and friends of ${this.memberName}
     after the funeral at ${gatheringPlace} ${gatheringLocation}. The time of the gathering will be ${gatheringDate} at
      ${gatheringTime}. We hope you can join family & friends in this celebration of life.`;

    // Optionally update the form control
    this.createIteneraryForm.get('gatheringInvitationText').setValue(this.gatheringInvitationText, { emitEvent: false });
    this.cdr.detectChanges();
  }

  updateGuestInvitationText(){
    const nameOfPlace = this.createIteneraryForm.get('nameOfPlace').value || '';
    const searchLocation = this.createIteneraryForm.get('searchLocation').value || 'XYZ location';
    const ceremonyDateValue = this.createIteneraryForm.get('ceremonyDate').value;
    const ceremonyDate = ceremonyDateValue
      ? this.datePipe.transform(new Date(ceremonyDateValue), 'MM/dd/yyyy')
      : 'date';
      const ceremonyTime = this.createIteneraryForm.get('ceremonyTime').value || 'time';

    this.invitationText = `We invite you to join as at ${this.memberName}’s final resting place at ${nameOfPlace} ${searchLocation} on ${ceremonyDate} at ${ceremonyTime}.`;

    // Optionally update the form control
    this.createIteneraryForm.get('invitationText').setValue(this.invitationText, { emitEvent: false });
    this.cdr.detectChanges();
  }

  setupAutocomplete(inputElement: ElementRef, controlName: string, latitude, longitude) {
    const autocomplete = new google.maps.places.Autocomplete(inputElement.nativeElement, {
      types: ['address']
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        this.createIteneraryForm.get(controlName).setValue(place.formatted_address);

        const lat = place.geometry.location.lat();
  const lng = place.geometry.location.lng();
  this.createIteneraryForm.get(latitude).setValue(lat);
  this.createIteneraryForm.get(longitude).setValue(lng);
  if(controlName == 'gatheringLocation'){
    setTimeout(() => {
      this.updateInvitationText();
    }, MagicNumbers.thousand);
  } else{
    setTimeout(() => {
      this.updateGuestInvitationText();
    }, MagicNumbers.thousand);
  }
      }
    });
  }

  getCurrentLocation(controlName: string, latitude, longitude) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        this.createIteneraryForm.get(latitude).setValue(lat);
  this.createIteneraryForm.get(longitude).setValue(lng);
        this.geocodeLatLng(lat, lng, controlName);
      }, (error) => {
      });
    } else {
    }
  }

  geocodeLatLng(lat: number, lng: number, controlName: string) {
    const latlng = new google.maps.LatLng(lat, lng);
        const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          if(controlName == 'gatheringLocation'){
            this.updateInvitationText();
          } else{
            this.updateGuestInvitationText();
          }
          this.createIteneraryForm.get(controlName).setValue(results[0].formatted_address);
        } else {
        }
      } else {
      }
    });
  }

  submitDetails(): void {
    const formattedCeremonyDate = this.datePipe.transform(this.createIteneraryForm.value.ceremonyDate, 'MMM d, y');
    const formattedGatheringDate = this.datePipe.transform(this.createIteneraryForm.value.gatheringDate, 'MMM d, y');
    const data = {
      member_id: this.memberId,
      interment_place: this.createIteneraryForm.value.nameOfPlace,
      interment_location: this.createIteneraryForm.value.searchLocation,
      interment_latitude: this.createIteneraryForm.value.searchLatitude ? this.createIteneraryForm.value.searchLatitude.toString(): null,
      interment_longitude: this.createIteneraryForm.value.searchLongitude ? this.createIteneraryForm.value.searchLongitude.toString(): null,
      interment_ceremony_date: this.createIteneraryForm.value.ceremonyDate ? formattedCeremonyDate : null,
      interment_ceremony_time: this.createIteneraryForm.value.ceremonyTime,
      interment_invitation_text: this.createIteneraryForm.value.invitationText,
      celebration_of_life_place: this.createIteneraryForm.value.gatheringPlace,
      celebration_of_life_address: this.createIteneraryForm.value.gatheringLocation,
      celebration_of_life_latitude: this.createIteneraryForm.value.gatheringLatitude ? this.createIteneraryForm.value.gatheringLatitude.toString(): null,
      celebration_of_life_longitude: this.createIteneraryForm.value.gatheringLongitude ? this.createIteneraryForm.value.gatheringLongitude.toString(): null,
      celebration_of_life_gathering_date: this.createIteneraryForm.value.gatheringDate ? formattedGatheringDate : null,
      celebration_of_life_gathering_time: this.createIteneraryForm.value.gatheringTime,
      celebration_of_life_invitation_text: this.createIteneraryForm.value.gatheringInvitationText
    };

    if (this.createIteneraryForm.valid) {
      this.openConfirmationDialog(() => {
        this.loader.show();
        this.memberService.addItenerary(data).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.loader.hide();
            this.toastr.success(res.message);
            this.router.navigate(['/view-itinerary/', this.memberId]);
          } else {
            this.loader.hide();
            this.createIteneraryForm.markAllAsTouched();
          }
        });
      });
    } else {
      this.createIteneraryForm.markAllAsTouched();
    }
    }

    openConfirmationDialog(confirmCallback: () => void): void {
      const modalRef = this.modalService.show(ConfirmItineraryComponent, {
        class: 'signin-modal signin-modal-height confirm-itinerary-modal',
        initialState: {
          // message: 'Are you sure you want to submit?',
          confirmCallback: confirmCallback,
          cancelCallback: () => {
            // Optional: Add any code to handle cancellation
          },
        },
      });
    }

  get formControls() {
    return this.createIteneraryForm.controls;
  }
  addClassDatePicker(className) {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', className);
    }, 0);

  }

  onValueChange(e: any, type: string) {
    // Handle date or time change events
    if (type === 'ceremonyDate') {
      this.searchObj.ceremonyDate = this.convertToDateString(e);
      this.createIteneraryForm.controls[type].setValue(this.convertToDateString(e));
    } else if (type === 'ceremonyTime') {
      this.searchObj.ceremonyTime = e.target.value;
      this.createIteneraryForm.controls[type].setValue(e.target.value);
    }  else if (type === 'gatheringDate') {
      this.searchObj.gatheringDate = e;
      this.createIteneraryForm.controls[type].setValue(this.convertToDateString(e));
    }  else if (type === 'gatheringTime') {
      this.searchObj.gatheringTime = e.target.value;
      this.createIteneraryForm.controls[type].setValue(e.target.value);
    }
  }

   convertToDateString(dateString) {
    if(dateString){
      const date = new Date(dateString);

      // Check if the Date object is valid

      // Use toISOString() to get the full date-time string in ISO format
      const isoString = date.toISOString();

      // Extract the date part (YYYY-MM-DD)
      const datePart = isoString.split('T')[0];
      return datePart;
    } else {
      return undefined;
    };
    // Create a Date object from the input date string

  }

  getMember() {
    this.loaderService.show();
    this.searchService
      .getViewInfo(this.memberId)
      .subscribe((res: any) => {
        if (res && !res.errors) {
          this.memberName = res.data.name;
        }
        this.updateInvitationText();
        this.updateGuestInvitationText();
        this.loaderService.hide();
      },(error)=>{
      });
  }

  openEditGuestInvitation() {
    const initialState = {
      invitationText: this.invitationText
    };

    const modalRef = this.modalService.show(EditInvitationComponent, {
      class: 'signin-modal signin-modal-height edit-invite-modal',
      initialState
    });

    modalRef.content.invitationTextUpdated.subscribe(updatedText => {
      this.invitationText = updatedText;
      this.createIteneraryForm.get('invitationText').setValue(updatedText);
    });
  }

  openEditInvitation() {
    const initialState = {
      invitationText: this.gatheringInvitationText
    };

    const modalRef = this.modalService.show(EditInvitationComponent, {
      class: 'signin-modal signin-modal-height edit-invite-modal',
      initialState
    });

    modalRef.content.invitationTextUpdated.subscribe(updatedText => {
      this.gatheringInvitationText = updatedText;
      this.createIteneraryForm.get('gatheringInvitationText').setValue(updatedText);
    });
  }

}
