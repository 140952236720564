import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../common/interfaces/response';
import { MemberService } from '../../common/services/member.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchService } from '../../common/services/search.service';

@Component({
  selector: 'app-memory-code-page',
  templateUrl: './memory-code-page.component.html',
  styleUrls: ['./memory-code-page.component.scss']
})
export class MemoryCodePageComponent implements OnInit {
  public successData:any;
  queryyy: any;
  queryyyData: any;
  urlQr: any;
  getMember: any;
  member: any;
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  id: any;

  constructor(public activatedRoute: ActivatedRoute, public memberService: MemberService, public searchService: SearchService,
    public router: Router, public toastr: ToastrService, private readonly deviceService: DeviceDetectorService) {
      this.activatedRoute.params.subscribe((params) => {
        if (params.id) {
          this.id = params.id;
        }
      });
      this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.data) {
        this.successData = JSON.parse(queryParams.data);
        this.queryyy = queryParams;
        this.queryyyData = queryParams.data;

      }
    });
    this.epicFunction();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
   this.member =  JSON.parse(localStorage.getItem('userData'));
   this.getMemberData();
    this.getQrCode();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo().os;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  /**
   * To get the qr memory code based on member id in success data
  */
  getQrCode() {
    this.memberService.downloadQr(this.id).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.successData = res.data;
      }
    });
  }

    getMemberData() {
      this.searchService
        .getViewInfo(this.id)
        .subscribe((res: any) => {
          if (res && !res.errors) {
            this.getMember = res.data;
          }
        });
    }
  /**
     * To download the qr memory code 
    */
  async downloadCode() {
    fetch(this.successData)
      .then(response =>
        response.blob()
      )
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = this.successData.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }


  printCode() {
    if(this.isMobile === true && this.deviceInfo === 'Android'){
      window.open(this.successData, '_blank');
    } else{
    let win = window.open('');
    win.document.write('<img src="' + this.successData + '" onload="window.print();window.close()" />');
    win.focus();
    }
  }

  /**
   * To view the member information in other tab
  */
  viewInformation(data) {
    if (localStorage.getItem('userData')) {
      window.open(data.url, '_blank');
    }
  }
  /**
   * To copy the url 
  */
  copyUrl(url) {
    const routeUrl = url;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = routeUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Link Copied');
  }

  copyMemoryCode(url) {
    const routeUrl = url;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = routeUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('QR Memory Code Copied');
  }

}
