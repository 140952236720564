
import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../../app/common/interfaces/response';
import { UtilityService } from '../../../../app/common/services/utility.service';
import { MemberService } from '../../../common/services/member.service';
import { MagicNumbers } from 'src/app/common/component/app-magic-numbers';

@Component({
  selector: 'app-invite-co-owner',
  templateUrl: './invite-co-owner.component.html',
  styleUrls: ['./invite-co-owner.component.scss']
})
export class InviteCoOwnerComponent implements OnInit, OnDestroy{


  form: FormGroup;
  memberList: any;
  public bodyElement;



  constructor(public ref: ChangeDetectorRef, public http: HttpClient,
    public router: Router, public fb: FormBuilder, public utilityService: UtilityService, public inj: Injector, public memberService: MemberService) {
    this.form = this.fb.group({
      users: this.fb.array([this.createUserFormGroup()], this.duplicateEmailValidator)
    });

    this.loader = inj.get<NgxSpinnerService>(NgxSpinnerService);
    this.toastr = inj.get<ToastrService>(ToastrService);
  }

  public loader: NgxSpinnerService;
  public toastr: ToastrService;



  ngOnInit(): void {
    this.getMemberList();
    this.bodyElement = document.body;
    this.bodyElement.classList.add('remove-padding');
  }

  createUserFormGroup(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z -]+$'), Validators.maxLength(MagicNumbers.thirty), Validators.minLength(MagicNumbers.two)]],
      email: ['', [Validators.required, Validators.email]],
      account_type: [null, Validators.required],
      selectedState: [false]
    });
  }

  get users(): FormArray {
    return this.form.get('users') as FormArray;
  }

  addUser(): void {
    // Ensure the form is valid before adding a new user
    if (this.form.valid && this.users.valid) {
      const newUser = this.createUserFormGroup();
      // Ensure the controls are pristine and untouched
      newUser.markAsPristine();
      newUser.markAsUntouched();
      this.users.push(newUser);
      this.ref.detectChanges();
    } else {
      this.form.markAllAsTouched();
    }
  }

  resetForm(): void {
    this.form.reset();
    this.users.clear();
    this.addUser(); // Ensure at least one row remains
  }

  removeUser(index: number): void {
    if (this.users.length > 1) {
      this.users.removeAt(index);
    } else {
      this.toastr.error('At least one row must be present.');
    }
  }

  duplicateEmailValidator(formArray: FormArray): { [key: string]: boolean } | null {
    const emailMap = new Map<string, boolean>();
    for (const control of formArray.controls) {
      const email = control.get('email')?.value;
      if (email && emailMap.has(email)) {
        control.get('email')?.setErrors({ duplicate: true });
        return { duplicateEmails: true };
      }
      emailMap.set(email, true);
    }
    return null;
  }

  onSubmit(): void {
    if (this.form.valid) {

      const payload = this.form.value.users.map((user: any) => ({
        name: user.name,
        email: user.email,
        account_type: user.account_type
      }));

      this.utilityService.inviteCoOwners(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.resetForm();
          const userInfo = JSON.parse(localStorage.getItem('userData'));
          if(userInfo.members.length != 0){
            this.router.navigate(['/members-list']);
          } else{
            this.router.navigate(['/home']);
          }
        } else {
          this.form.markAllAsTouched();
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  back(){
    if(this.memberList.length != 0){
      this.router.navigate(['/members-list']);
    } else{
      this.router.navigate(['/home']);
    }
  }

  getMemberList() {
      this.memberService
        .memberList()
        .subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.memberList = res.data;
          }
        },(error)=>{

        });
  }
  ngOnDestroy() {
    this.bodyElement.classList.remove('remove-padding');
  }

}
